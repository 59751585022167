import React, { useEffect, useState, Fragment, useRef, useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { ExclamationIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'
import Header from '../../Components/HeaderAdmin'
import { Table, Space, Button, Select, Modal, Input, Form } from 'antd';
import { ListModul, CreateModul, DeleteModul, UpdateModul, UpdateSortModul } from '../../Configs/Api'

const type = 'DraggableBodyRow';
// import WelcomeIcon from '../../Assets/icon/begin.png'
// import CongratsIcon from '../../Assets/icon/end.png'
// import DataIcon from '../../Assets/icon/data.png'
// import MarketingIcon from '../../Assets/icon/marketing.png'
// import ConnectionIcon from '../../Assets/icon/connection.png'
// import ConversionIcon from '../../Assets/icon/conversion.png'
// import ItIcon from '../../Assets/icon/it.png'
// import GroomingIcon from '../../Assets/icon/grooming.png'
// import StartIcon from '../../Assets/icon/start.png'
// import TaxIcon from '../../Assets/icon/tax.png'
// import EndIcon from '../../Assets/icon/end.png'
// import Buy from '../../Assets/icon/buy.png'

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export default function Training() {
  const [Delete, setDelete] = useState(false)
  const [Add, setAdd] = useState(false)
  const [Edit, setEdit] = useState(false)
  const [paramUpdate, setParamUpdate] = useState(false)
  const [people, setPeople] = useState([])
  const [nameModul, setNameModul] = useState('')
  const [iconModul, setIconModul] = useState('')
  const [iconModulEdit, setIconModulEdit] = useState('')
  const [nameModulEdit, setNameModulEdit] = useState('')
  const [idModul, setIdModul] = useState('')
  const [idModulEdit, setIdModulEdit] = useState('')
  const cancelButtonRef = useRef(null)
  const { Option } = Select;
  const DataImage = [
    { url: 'https://img.icons8.com/clouds/2x/handshake.png', name: 'image-1' },
    { url: 'https://img.icons8.com/clouds/2x/play.png', name: 'image-2' },
    { url: 'https://img.icons8.com/clouds/2x/data-configuration.png', name: 'image-3' },
    { url: 'https://img.icons8.com/clouds/2x/export.png', name: 'image-4' },
    { url: 'https://img.icons8.com/clouds/2x/cash-in-hand.png', name: 'image-5' },
    { url: 'https://img.icons8.com/clouds/344/barbershop.png', name: 'image-6' },
    { url: 'https://img.icons8.com/clouds/2x/share.png', name: 'image-7' },
    { url: 'https://img.icons8.com/clouds/2x/omnichannel.png', name: 'image-8' },
    { url: 'https://img.icons8.com/clouds/2x/bill.png', name: 'image-9' },
    { url: 'https://img.icons8.com/clouds/2x/cloud-mail.png', name: 'image-10' },
    { url: 'https://img.icons8.com/clouds/2x/scales.png', name: 'image-11' },
    { url: 'https://img.icons8.com/clouds/344/laptop.png', name: 'image-12' },
    { url: 'https://img.icons8.com/bubbles/2x/handshake.png', name: 'image-13' },
  ]

  useEffect(() => {
    getListModul()
  }, []);

  useEffect(() => {
    if (paramUpdate) {
      UpdateSort()
    }
  });
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
      const dragRow = await people[dragIndex];
      setPeople(
        update(people, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setParamUpdate(true)
    },
    [people],
  );
  const getListModul = async () => {
    const data = await ListModul('0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          "name": data.name,
          "icon_path": data.no_identitas,
          "id": data.id,
          "Data": data,
        })
      })
      setParamUpdate(false)
      setPeople(array)
    }
  }
  const TambahModul = async () => {
    var formData = new FormData();
    formData.append('name', nameModul);
    formData.append('icon', iconModul);
    const data = await CreateModul(formData)
    if (data.status) {
      getListModul()
      setNameModul('')
      setAdd(!Add)
    }
  }
  const UbahModul = async () => {
    var id = idModulEdit
    var formData = new FormData();
    formData.append('name', nameModulEdit);
    formData.append('icon', iconModulEdit);
    formData.append('_method', 'PUT');
    const data = await UpdateModul(id, formData)
    if (data.status) {
      getListModul()
      setNameModulEdit('')
      setIconModulEdit('')
      setEdit(!Edit)
    }
  }
  const UpdateSort = async () => {
    var formData = new FormData();
    formData.append('data', JSON.stringify(people));
    const data = await UpdateSortModul(formData)
    if (data.status) {
      setParamUpdate(false)
      getListModul()
    }
  }
  const deleteModul = (id) => {
    setIdModul(id)
    setDelete(!Delete)
  }
  const trueDeleteModul = async () => {
    var id = idModul
    const data = await DeleteModul(id)
    if (data.status) {
      setIdModul('')
      setDelete(!Delete)
      getListModul()
    }
  }
  const EditModul = (id, name) => {
    setNameModulEdit(name)
    setIdModulEdit(id)
    setEdit(!Edit)
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '80%',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '20%',
      render: (Data) => (
        <Space size="middle">
          <PencilIcon onClick={() => EditModul(Data.id, Data.name)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          <TrashIcon onClick={() => deleteModul(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
        </Space>
      ),
    },
  ];
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full h-full bg-white shadow-md p-3">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'> Modul Training</div>
            <Button type="primary" onClick={() => setAdd(true)}>Tambah</Button>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <DndProvider backend={HTML5Backend}>
                <Table
                  columns={columns}
                  dataSource={people}
                  pagination={true}
                  components={components}
                  bordered
                  onRow={(record, index) => ({
                    index,
                    moveRow,
                  })}
                />
              </DndProvider>
            </div>
          </div>
          <Transition.Root show={Delete} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setDelete}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Delete Modul
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete this Modul ? All of your data will be permanently removed.
                              This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <Button type="danger" className='m-1' onClick={() => trueDeleteModul()}>Delete</Button>
                      <Button type="primary" className='m-1' onClick={() => setDelete(false)}>Cancel</Button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
          <Modal title="Edit Modul" visible={Edit} onOk={() => UbahModul()} onCancel={() => setEdit(false)}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Name">
                <Input value={nameModulEdit} onChange={(e) => setNameModulEdit(e.target.value)} />
              </Form.Item>
              <Form.Item label="Icon">
                <Select
                  onChange={e => setIconModulEdit(e)}
                  size='large'
                  showSearch
                  placeholder="Search to Select"
                >
                  {
                    DataImage.map((data, i) => (
                      <Option key={i} value={data.url}><img style={{ height: '40px', width: '40px' }} src={data.url} /></Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Form>
          </Modal>
          <Modal title="Tambah Modul" visible={Add} onOk={() => TambahModul()} onCancel={() => setAdd()}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Name">
                <Input value={nameModul} onChange={(e) => setNameModul(e.target.value)} />
              </Form.Item>
              <Form.Item label="Icon">
                <Select
                  onChange={e => setIconModul(e)}
                  size='large'
                  showSearch
                  placeholder="Search to Select"
                >
                  {
                    DataImage.map((data, i) => (
                      <Option key={i} value={data.url}><img style={{ height: '40px', width: '40px' }} src={data.url} /></Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </div >
      </div >
    </>
  )
}
