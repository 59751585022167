import React from "react";
import LmLogo from '../../Assets/brand/android-icon-144x144.png';

const WhoIsLM = () => {
    return (
    <div>
        <section className="h-full">
        <div className="w-full h-full relative py-10">
            <div className="lg:flex items-center relative z-10">
            <div className="flex flex-col items-center w-full px-4">
                <img src={LmLogo} className="mb-8" alt="lm logo"/>
                <h1 tabIndex="0" className="text-2xl lg:text-5xl font-bold mb-8 md:mb-12">Who is Loan Market?</h1>
                <p tabIndex="0" className="text-lg mb-4 text-center max-w-screen-lg">Loan Market is Australiasia’s biggest broking family. Started by Sam White, great-grandson of Ray White, 
                as Ray White Financial Services in 1995, it has since grown to operate in Australia, New Zealand and Indonesia. It encompasses businesses that offer services across 
                a full suite of financial loans, including home, car, personal, commercial and SMSF. Together these businesses empower customers to make better financial decisions.
                </p>
            </div>
                {/* <Player
                className="w-full"
                poster="https://www.loanmarket.co.id/api_accelerate/public/img/thumbnail/video-thumbnail.jpg"
                src="https://loanmarket.co.id/api_accelerate/public/video/accelerate.mp4"
                /> */}
            </div>
        </div>
        </section>
    </div>
    )
}

export default WhoIsLM