import React, { useEffect, useState, Fragment, useRef, useMemo } from 'react';
import { ExclamationIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Table, Space, Form, Input, Button, Modal, Select } from 'antd';
import Header from '../../Components/HeaderAdmin'
import { ListMateri, CreateMateri, DeleteMateri, UpdateMateri, ListModul, UploadImages } from '../../Configs/Api'
import JoditEditor from "jodit-react";


export default function MateriTraining() {
  const editor = useRef(null)
  const [Delete, setDelete] = useState(false)
  const [Add, setAdd] = useState(false)
  const [EditModul, setEditMateri] = useState(false)
  const [idMateriDelete, setIdMateriDelete] = useState([])
  const [dataModul, setDataModul] = useState([])
  const [dataMateri, setDataMateri] = useState([])
  const [dataCreateMateri, setDataCreateMateri] = useState({
    modulCreate: '',
    contentCreate: '',
    titleCreate: '',
  })
  const [dataUpdateMateri, setDataUpdateMateri] = useState({
    idUpdate: '',
    modulUpdate: '',
    contentUpdate: '',
    titleUpdate: '',
  })
  const cancelButtonRef = useRef(null)
  const { Option } = Select;
  const { idUpdate, modulUpdate, contentUpdate, titleUpdate } = dataUpdateMateri
  const updateDataMateri = (data) => setDataUpdateMateri((state) => ({ ...state, ...data }));
  const { modulCreate, contentCreate, titleCreate } = dataCreateMateri
  const createDataMateri = (data) => setDataCreateMateri((state) => ({ ...state, ...data }));

  useEffect(() => {
    getListMateri()
  }, []);

  const getListMateri = async () => {
    const data = await ListMateri('0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "title": data.title,
          "modul": data.modul,
          "content": data.content,
          "idModul": data.id_modul,
          "id": data.id,
          "Data": data,
        })
      })
      setDataMateri(array)
    }
  }

  const getListModul = async () => {
    const data = await ListModul('0')
    if (data.status) {
      setDataModul(data.data.data)
    }
  }
  const handleDeleteMateri = async () => {
    var Id = idMateriDelete
    const data = await DeleteMateri(Id)
    if (data.status) {
      setIdMateriDelete('')
      getListMateri()
      setDelete(!Delete)
    }
  }
  const deleteMateri = (id) => {
    setIdMateriDelete(id)
    setDelete(!Delete)
  }

  const createMateri = async () => {
    var formData = new FormData();
    formData.append('title', titleCreate);
    formData.append('modul', modulCreate);
    formData.append('content', contentCreate);
    const data = await CreateMateri(formData)
    if (data.status) {
      getListMateri()
      createDataMateri({
        modulCreate: '',
        contentCreate: '',
        titleCreate: '',
      })
      setAdd(!Add)
    }
  }

  const updateMateri = async () => {
    setEditMateri(false)
    var Id = idUpdate
    var formData = new FormData();
    formData.append('title', titleUpdate);
    formData.append('modul', modulUpdate);
    formData.append('content', contentUpdate);
    formData.append('_method', 'PUT');
    const data = await UpdateMateri(Id, formData)
    if (data.status) {
      getListMateri()
      updateDataMateri({
        idUpdate: '',
        modulUpdate: '',
        contentUpdate: '',
        titleUpdate: '',
      })
    }
  }
  const handleAddMateri = () => {
    getListModul()
    getListMateri()
    setAdd(!Add)
  }
  const handleEditMateri = (data) => {
    updateDataMateri({
      idUpdate: data.id,
      titleUpdate: data.title,
      modulUpdate: data.idModul,
      contentUpdate: data.content,
    })
    // getListMateri()
    setEditMateri(!EditModul)
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Modul',
      dataIndex: 'modul',
      key: 'modul',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <PencilIcon onClick={() => handleEditMateri(Data)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          <TrashIcon onClick={() => deleteMateri(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
        </Space>
      ),
    },
  ];
  const TOKEN_KEY = 'TOKEN';
  const Token = atob(localStorage.getItem(TOKEN_KEY))
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      url: 'https://apiaccelerate.loanmarket.co.id/api/uploads',
      headers: { Authorization: `Bearer ${Token}` }
    }
  }
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full  bg-white shadow-md p-3 min-h-half">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'> Daftar Modul</div>
            <Button type="primary" onClick={() => handleAddMateri()}>Tambah</Button>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <Table
                columns={columns}
                dataSource={dataMateri}
                scroll={{ x: 1000, y: 500 }}
                pagination={true}
                bordered
              />
            </div>
          </div>
          <Transition.Root show={Delete} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setDelete}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Delete User
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete this User ? All of your data will be permanently removed.
                              This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => handleDeleteMateri()}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setDelete(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
          <Modal maskClosable={false} title="Ubah Materi" width={1200} visible={EditModul} onOk={e => updateMateri()} onCancel={e => setEditMateri(false)}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Title">
                <Input value={titleUpdate} onChange={e => updateDataMateri({ titleUpdate: e.target.value })} />
              </Form.Item>
              <Form.Item label="Modul">
                <Select
                  value={modulUpdate}
                  onChange={e => updateDataMateri({ modulUpdate: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {dataModul.length > 0 ?
                    dataModul.map((data, idx) => (
                      <Option key={idx} value={data.id}>{data.name}</Option>
                    ))
                    :
                    null
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Content">
                <JoditEditor
                  ref={editor}
                  value={contentUpdate}
                  config={config}
                  tabIndex={1} // tabIrred to use only this option to update the content for performance reasons
                  onChange={newContent => updateDataMateri({ contentUpdate: newContent })}
                />
                {/* <ReactQuill
                  ref={(el) => {
                    quillObj = el;
                  }}
                  formats={formats}
                  theme="snow"
                  value={contentUpdate}
                  onChange={e => updateDataMateri({ contentUpdate: e })}
                  modules={modules}
                /> */}
              </Form.Item>
            </Form>
          </Modal>
          <Modal maskClosable={false} title="Tambah Materi" width={1200} visible={Add} onOk={e => createMateri()} onCancel={e => setAdd(false)}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Title">
                <Input value={titleCreate} onChange={e => createDataMateri({ titleCreate: e.target.value })} />
              </Form.Item>

              <Form.Item label="Modul">
                <Select
                  value={modulCreate}
                  onChange={e => createDataMateri({ modulCreate: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {dataModul.length > 0 ?
                    dataModul.map((data, idx) => (
                      <Option key={idx} value={data.id}>{data.name}</Option>
                    ))
                    :
                    null
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Content">
                <JoditEditor
                  ref={editor}
                  value={contentCreate}
                  config={config}
                  tabIndex={1}
                  onChange={newContent => createDataMateri({ contentCreate: newContent })}
                />
                {/* <ReactQuill
                  ref={(el) => {
                    quillObj = el;
                  }}
                  formats={formats}
                  theme="snow"
                  value={contentCreate}
                  onChange={e => createDataMateri({ contentCreate: e })}
                  modules={modules}

                /> */}
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  )
}
