import React from "react";
// import BgMenu from '../../Assets/lmway1-jumborton-bg.png';
import BgMenu from '../../Assets/lmway1-jumborton-bg2.png';

const Jumbotron = () => {
    return (
    <div className="h-fit">
      <section className="h-full">
        <div className="w-full h-full relative pb-20 pt-32 md:pt-52">
          <img className="absolute md:w-full inset-0 h-full object-cover object-center" src={BgMenu} alt="we care family" />
          <div className="lg:flex items-center relative z-10">
            <div className="flex flex-col items-center w-full px-4">
              <h1 tabIndex="0" className="text-stone-900 text-2xl lg:text-5xl font-bold mb-8 md:mb-20">Loan Market Way 1</h1>
              <p tabIndex="0" className="text-stone-900 text-xl md:text-2xl mb-4 text-center md:px-16 max-w-screen-lg">
              Loan Market Way is a training & development program by Loan Market Indonesia to prepare our newest Loan Advisers to gain more familiarity with how Loan Market Indonesia conducts its business. Here, we’ll help you develop your skills and knowledge in becoming productive Loan Advisers to help our customers in giving the best financial solution they need.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}

export default Jumbotron