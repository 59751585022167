import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import Header from '../../Components/HeaderAdmin'
import { Note, CreateNote } from '../../Configs/Api'

import ReactQuill from 'react-quill';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],         // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['link', 'video'],
    ['link', 'image', 'video'],
    ['clean']                                    // remove formatting button
  ]
}
export default function Notes() {
  const [dataNote, setDataNote] = useState([])
  const [title, setTitle] = useState([])
  useEffect(() => {
    getNote()
  }, []);
  const getNote = async () => {
    const data = await Note()
    if (data.status) {
      setDataNote(data.data.data.content)
      setTitle(data.data.data.title)
    }
  }
  const createNote = async () => {
    var formData = new FormData();
    formData.append('title', title);
    formData.append('content', dataNote);
    const data = await CreateNote(formData)
    if (data.status) {
      getNote()
    }
  }
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full  bg-white shadow-md p-3 min-h-half">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'>Note</div>
          </div>
          <div className="w-full h-full mt-4 p-4">
            <Form
              layout="vertical"
            >
              <Form.Item label="Title">
                <Input onChange={e => setTitle(e.target.value)} value={title} />
              </Form.Item>
              <Form.Item label="Content">
                <ReactQuill theme="snow" modules={modules} onChange={e => setDataNote(e)} value={dataNote} />
              </Form.Item>
            </Form>
            <div className='text-right'>
              <Button type='primary' onClick={e => createNote()}>Update</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
