import React, { useEffect } from 'react';
import Headers from '../../Components/Headers';
import Footers from '../../Components/Footers';
import Menu from '../../Components/ChangePassword';
import HeadersNew from '../../Components/New/Headers';

const Index = (props) => {
  useEffect(() => {
    window.sessionStorage.removeItem('Quiz');
  }, [])
  return (
    <div className='bg-gray-100'>
      <HeadersNew />
      <Menu/>
      <Footers />
    </div>
  );
}
export default Index