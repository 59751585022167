import { Empty, message } from "antd";
import React, { useEffect, useState } from "react";
import { ListModulMenu } from "../../Configs/Api";
import QuizProsesPopup from "./QuizProsesPopup";

function Index(props) {
  const [Menu, setMenu] = useState([]);
  const userRole = JSON.parse(localStorage.getItem('User')).role;
  
  useEffect(() => {
    localStorage.removeItem('TrainingClass')
    GetMenu();
  }, []);
  
  const handlePresentation = (data, nilai_sebelum, idx) => {
    // if (data.status === true && nilai_sebelum && idx > 1) {
    //   localStorage.setItem('idModul', btoa(data.id));
    //   localStorage.setItem('TrainingClass', btoa(data.id_training_class));
    //   props.history.history.push(data.path);
    // } else if (data.status === true && (idx === 0 || idx === 1)) {
    //   localStorage.setItem('idModul', btoa(data.id));
    //   localStorage.setItem('TrainingClass', btoa(data.id_training_class));
    //   props.history.history.push(data.path);
    // } else if (userRole === "trainer") {
    //   localStorage.setItem('idModul', btoa(data.id));
    //   localStorage.setItem('TrainingClass', btoa(data.id_training_class));
    //   props.history.history.push(data.path);
    // } else if (!nilai_sebelum && data.status === true) {
    //   message.error('Anda belum mengerjakan Quiz pada modul sebelumnya. Mohon untuk mengerjakan terlebih dahulu.');
    // }
    //   else {
    //   message.error('No schedule for today');
    // }
    console.log(idx)
    if (!nilai_sebelum && idx !== 1) {
      message.error('Anda belum mengerjakan Quiz pada modul sebelumnya. Mohon untuk mengerjakan terlebih dahulu.');
    } else {
      localStorage.setItem('idModul', btoa(data.id));
      localStorage.setItem('TrainingClass', btoa(data.id_training_class));
      props.history.history.push(data.path);
    }
  }
  
  const GetMenu = async () => {
    const data = await ListModulMenu();
    if (data.status) {
      setMenu(data.data.modul);
      localStorage.setItem('Menu', JSON.stringify(data.data.modul));
    }
  }

  return (
    <div className="bg-white flex flex-col md:gap-y-12 items-center py-10 md:px-16 xl:px-32">
      <div className="text-stone-900 text-2xl lg:text-5xl font-bold">Courses Included</div>
      {/* Card is full width. Use in 12 col grid for best view. */}
      {/* Card code block start */}
      <div className="py-6 grid gap-x-8 gap-y-16 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {Menu.length > 0 ?
          Menu.map((data, idx) => {
            if (idx === 0) {
              return (
                <div className={`items-center justify-center card-menu rounded-2xl text-center rounded flex flex-col items-start shadow border-solid border border-slate-200 bg-stone-50`} 
                key={idx} onClick={e => handlePresentation(data, 70, idx)}>
                  <div className="items-center justify-center py-10">
                    <img
                      style={{ maxHeight: '100px' }}
                      className={`mx-auto`}
                      src={data.icon}
                      alt="Workflow"
                    />
                  </div>
                  <div className={`h-full w-full rounded-b-[14px] py-2 px-4 bg-slate-800`}>
                    <p className="text-white mt-2 mb-6 text-md  tracking-wide font-bold leading-normal">{idx+1}. {data.name}</p>
                  </div>
                </div>
              );
            } else if (idx > 0) {
              return (
                <div className={`items-center justify-center card-menu rounded-2xl text-center rounded flex flex-col items-start shadow border-solid border border-slate-200 ${(data.status && Menu[idx - 1].name === 'Our Culture') || (data.status && !Menu[idx - 1].nilai) || userRole === "trainer" ? "bg-stone-50" : "opacity-95"}`} key={idx} onClick={e => handlePresentation(data, Menu[idx - 1].nilai, idx)}>
                  <div className="items-center justify-center py-10">
                    <img
                      style={{ maxHeight: '100px' }}
                      className={`mx-auto ${Menu[idx - 1].nilai || Menu[idx - 1].name === 'Our Culture' || userRole === "trainer" ? "" : "opacity-25"}`}
                      src={data.icon}
                      alt="Workflow"
                    />
                  </div>
                  <div className={`h-full w-full rounded-b-[14px] py-2 px-4 ${Menu[idx - 1].nilai || Menu[idx - 1].name === 'Our Culture' || userRole === "trainer" ? "bg-slate-800" : "bg-gray-200"}`}>
                    <p className="text-white mt-2 mb-6 text-md  tracking-wide font-bold leading-normal">{idx+1}. {data.name}</p>
                  </div>
                </div>
              );
            }
          })
          :
          <Empty />}
      </div>
      {/* Card code block end */}
    </div >
  );
}
export default Index;
//test