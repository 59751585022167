import React from "react";
// import BgMenu from '../../Assets/lmway1-jumborton-bg.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDollarSign, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import Cost from "../../Assets/Money.svg";
import Person from "../../Assets/Person.svg";
import Timer from "../../Assets/Timer.svg";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill1Wave, faHourglassEnd,faBullseye } from '@fortawesome/free-solid-svg-icons';
// import { faUser } from '@fortawesome/free-regular-svg-icons';


const LmWay1Intro = () => {
  const content = [{logo: faMoneyBill1Wave, rightLogo: "Cost", bottomLogo: "Free"}, {logo: faHourglassEnd, rightLogo: "Duration", bottomLogo: "4 Hours"}, {logo: faBullseye, rightLogo: "Target", bottomLogo: "New Loan Adviser"}]


    return (
    <div>
      <section className="h-full text-white bg-slate-800">
        <div className="w-full h-full relative py-10">
          <div className="lg:flex items-center relative z-10">
            <div className="flex flex-col items-center w-full px-4">
              <h1 tabIndex="0" className="text-white text-2xl lg:text-5xl font-bold">Why LM Way 1 ?</h1>
              <p className="text-lg mb-16 text-center">LM Way 1 is driven to help Loan Adviser fast track their success.</p>
              <div className="flex flex-col md:flex-row gap-4 md:gap-32">
                    {content.map((item, y) => (
                      <span className="flex flex-col items-center" key={y}>
                        <div className="flex content-center gap-2 mb-2 items-center">
                            <FontAwesomeIcon icon={item.logo} size="2x"/>
                            <p className="text-3xl mb-0">{item.rightLogo}</p>
                        </div>
                        <p className="w-fit text-xl">{item.bottomLogo}</p>
                    </span>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}

export default LmWay1Intro