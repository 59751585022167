import React from "react";

const DevelopedByCard = ({ photo, desc, name, role }) => {
    
    return (
        <div className="flex flex-col gap-2 items-center md:px-4">
            <img src={photo} className="rounded-full border-solid border-4 border-red-200 mb-4 bg-sky-400" alt="developer photo" width={"150px"}/>
            <p className="text-center text-lg mb-4">{desc}</p>
            <p className="text-center text-2xl mb-0 ">{name}</p>
            <p className="text-center text-xl mb-0">{role}</p>
        </div>
        )
}

export default DevelopedByCard