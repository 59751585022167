import React, { useEffect } from "react";
import HeadersNew from '../../../Components/New/Headers';
import Footers from '../../../Components/Footers';
import JumbotronAbout from "../../../Components/New/JumbotronAbout";
import WhoIsLM from "../../../Components/New/WhoIsLM";
import DevelopedBy from "../../../Components/New/DevelopedBy";

const About = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        window.sessionStorage.removeItem('Quiz');
    }, []);

    return (
        <div className='bg-gray-100'>
            <HeadersNew />
            <JumbotronAbout />
            {/* <Notes /> */}
            <WhoIsLM />
            <DevelopedBy />
            <Footers />
        </div>
    )
}

export default About