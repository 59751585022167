import React from "react";
import Benefits from '../../Assets/benefit-sec.svg';

import Benefits1 from '../../Assets/benefit1.png';
import Benefits2 from '../../Assets/benefit2.png';
import Benefits3 from '../../Assets/benefit3.png';

const TrainingBenefits = () => {
    const benefits = [{gambar:Benefits1, judul:"Improving your self-development", isi:"We're here because we care about your growth, this training will provide you the necessary knowledge which you can apply instantly to bring about significant improvements in your current job."},
    {gambar:Benefits2, judul:"More productivity", isi:"Productivity is one of the significant benefits of this training. You'll be better  to do your work efficiently and well-prepared with the right skills in facing obstacles."},
    {gambar:Benefits3, judul:"Specific guidance to help you", isi:"By this training, you’ll achieve what you know you’re capable of, with access to our industry leading tech, client management and compliance tools, coaching, and admin support."}]
    
    return (
        <div className="bg-white flex flex-col gap-y-12 items-center py-10 px-4 md:px-16 xl:px-32">
            <h2 className="text-2xl lg:text-5xl">Training Benefits</h2>
            <div className="grid md:grid-cols-3 gap-8 md:gap-16 xl:gap-32 ">
                {benefits.map((item, y) => 
                <div key={y} className="flex flex-col gap-y-2 items-center">
                    <img className="object-center h-32 md:h-64 w-32 md:w-64" src={item.gambar} alt="gambar benefit"/>
                    <h2 className="text-xl md:text-2xl text-center">{item.judul}</h2>
                    <p className="text-center">{item.isi}</p>
                </div>
                )}
            </div>
        </div>
    )
}

export default TrainingBenefits