import { Dropdown, Menu, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';

const menu = (
  <Menu
    items={[
      {
        key: '1',
        label: (
          <Link to="lm-way-1" rel="noopener noreferrer">
            LM Way 1
          </Link>
        ),
      },
      {
        key: '2',
        label: (
          <a rel="noopener noreferrer" href="#">
            LM Way 2
          </a>
        ),
      },
    ]}
  />
);

const App = () => (
  <Dropdown overlay={menu} className="text-black">
    <a onClick={(e) => e.preventDefault()}>
      <Space align="center">
        Training
        <FontAwesomeIcon icon={faChevronDown} />
      </Space>
    </a>
  </Dropdown>
);

export default App;