import React, { useEffect } from 'react';
import Headers from '../../Components/Headers';
import HeadersNew from '../../Components/New/Headers';
import Footers from '../../Components/Footers';
import Jumbotron from '../../Components/Jumbotron';
import JumbotronNew from '../../Components/New/Jumbotron';
import Menu from '../../Components/Menu';
import Notes from '../../Components/TrainerNotes';
import TrainingBenefits from '../../Components/New/TrainingBenefits';
import Reviews from '../../Components/New/Reviews';

const Index = (props) => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
    window.sessionStorage.removeItem('Quiz');
  }, []);

  return (
    <div className='bg-gray-100'>
      {/* <Headers /> */}
      <HeadersNew />
      {/* <Jumbotron /> */}
      <JumbotronNew />
      <TrainingBenefits />
      <Reviews />
      {/* <Menu history={props} /> */}
      {/* <Notes /> */}
      <Footers />
    </div>
  );
}
export default Index