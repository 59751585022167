import React, { useRef } from "react";
// import LmLogo from '../../Assets/brand/android-icon-144x144.png';
import Photo1 from '../../Assets/dev-by-sari-dewi.png';
import Photo2 from '../../Assets/dev-by-alfreds-wilson.png';
import Photo3 from '../../Assets/dev-by-handi-oetama.png';
import Photo4 from '../../Assets/dev-by-fajar-aswadi.png';
import Photo5 from '../../Assets/dev-by-indira-cader.png';


import DevelopedByCard from "./DevelopedByCard";
import Slider from "react-slick";

const DevelopedBy = () => {
    const experts = [{photo: Photo1, desc: "lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", name: "Sari Dewi", role: "-"},
                     {photo: Photo2, desc: "lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", name: "Alfreds Wilson", role: "-"},
                     {photo: Photo3, desc: "lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", name: "Handi Oetama", role: "-"},
                     {photo: Photo4, desc: "lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", name: "Fajar Aswadi", role: "-"},
                     {photo: Photo5, desc: "lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", name: "Indira Cader", role: "-"},
                     {photo: "", desc: "", name: "", role: ""}];

    const slider = useRef();

    const settings = {
        className: "center",
        centerMode: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        centerPadding: "10%",
        cssEase: 'linear',
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                centerPadding: "0",
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: "0",
            }
            }
        ],
        speed: 500,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3500
    };
    
    return (
        <div>
            <section className="h-full">
            <div className="w-full h-full relative pb-10 md:pb-0 md:py-10">
                <div className="lg:flex items-center relative z-10">
                    <div className="flex flex-col items-center w-full px-4">
                        <h1 tabIndex="0" className="text-xl lg:text-5xl font-bold mb-8 md:mb-12">Developed by industry experts</h1>
                        <p tabIndex="0" className="text-lg mb-16 text-center max-w-screen-lg">
                        Accelerate has been developed alongside leading subject matter experts from within the mortgage broking industry to ensure high quality content and functionality that meets the needs and expectations of those within real life brokerages.
                        </p>
                        <Slider ref={slider} {...settings} className="custom-slick-review w-full md:w-9/12 mb-20">
                            {experts.map((item, y) => {
                            if (y < experts.length - 1) {
                                return (
                                    <DevelopedByCard key={y} photo={item.photo} desc={item.desc} name={item.name} role={item.role} />
                                )
                            } else {
                                return (
                                    <div key={y}></div>
                                )
                            }  
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
            </section>
        </div>
        )
}

export default DevelopedBy