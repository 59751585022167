import React, { useEffect } from "react";
import HeadersNew from '../../../Components/New/Headers';
// import Jumbotron from '../../../Components/Jumbotron';
import Menu from '../../../Components/New/Menu';
import Notes from '../../../Components/TrainerNotes';
import Footers from '../../../Components/Footers';
import Jumbotron from "../../../Components/New/JumbotronLMWay1";
import LmWay1Intro from "../../../Components/New/LmWay1Intro";

const LmWay1 = (props) => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
        window.sessionStorage.removeItem('Quiz');
    }, []);

    return (
        <div className='bg-gray-100'>
            <HeadersNew />
            <Jumbotron />
            <LmWay1Intro />
            <Menu history={props} />
            {/* <Notes /> */}
            <Footers />
        </div>
    )
}

export default LmWay1