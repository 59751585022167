import React, { useEffect, useState } from 'react';
import { EyeIcon } from '@heroicons/react/outline'
import { Table, Space, Tooltip, Input, Button, Modal, Select } from 'antd';
import Header from '../../Components/HeaderAdmin'
import { ListFeedback } from '../../Configs/Api'

export default function Feedback() {
  const [dataFeedback, setDataFeedback] = useState([])
  useEffect(() => {
    getFeedback()
  }, []);
  const getFeedback = async () => {
    const data = await ListFeedback('0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "nama": data.name,
          "kantor": data.office,
          "judul": data.title,
          "id": data.id,
          "Data": data,
        })
      })
      setDataFeedback(array)
    }
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Nama',
      dataIndex: 'nama',
      key: 'nama',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Kantor',
      dataIndex: 'kantor',
      key: 'kantor',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Judul',
      dataIndex: 'judul',
      key: 'judul',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <Tooltip placement="top" title={'View'}>
            <EyeIcon className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full  bg-white shadow-md p-3 min-h-half">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'>Note</div>
          </div>
          <div className="w-full h-full mt-4 p-4">
            <Table
              columns={columns}
              dataSource={dataFeedback}
              scroll={{ x: 1000, y: 500 }}
              pagination={true}
              bordered
            />
          </div>
        </div>
      </div>
    </>
  )
}
