import axios from 'axios'
import API from './RestApi.json'
const TOKEN_KEY = 'TOKEN';
// const ACCESS = 'ACCESS';

if (localStorage.getItem(TOKEN_KEY) !== undefined) {
  const Token = atob(localStorage.getItem(TOKEN_KEY))
  var config = {
    headers: { Authorization: `Bearer ${Token}` }
  };
}


export const LoginUser = async (data) => {
  return await axios.post(API.Login, data
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const Password = async (data) => {
  return await axios.post(API.Password, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailMateri = async (id) => {
  return await axios.get(API.ListModul + '/' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UploadImages = async (form) => {
  return await axios.post(API.UploadImages, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListModul = async (row) => {
  return await axios.get(API.ListModul + '?row=' + row, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListModulMenu = async () => {
  return await axios.get(API.ListModul + '/create', config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const CreateModul = async (data) => {
  return await axios.post(API.ListModul, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateSortModul = async (data) => {
  return await axios.post(API.ListModul + '/sort', data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateModul = async (id, data) => {
  return await axios.post(API.ListModul + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteModul = async (id) => {
  return await axios.delete(API.ListModul + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListClass = async (idQuestion, row) => {
  return await axios.get(API.ListClass + '?row=' + row + '&question=' + idQuestion, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteClass = async (id) => {
  return await axios.delete(API.ListClass + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateClass = async (data) => {
  return await axios.post(API.ListClass, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateClass = async (id, data) => {
  return await axios.post(API.ListClass + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListLocation = async (row) => {
  return await axios.get(API.ListLocation + '?row=' + row, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteLocation = async (id) => {
  return await axios.delete(API.ListLocation + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateLocation = async (data) => {
  return await axios.post(API.ListLocation, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateLocation = async (id, data) => {
  return await axios.post(API.ListLocation + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListOffice = async () => {
  return await axios.get(API.ListOffice, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListUser = async (role, row) => {
  return await axios.get(API.ListUser + '?row=' + row + '&role=' + role, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteUser = async (id) => {
  return await axios.delete(API.ListUser + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateUser = async (data) => {
  return await axios.post(API.ListUser, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateUser = async (id, data) => {
  return await axios.post(API.ListUser + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListMateri = async (row) => {
  return await axios.get(API.ListMateri + '?row=' + row, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteMateri = async (id) => {
  return await axios.delete(API.ListMateri + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateMateri = async (data) => {
  return await axios.post(API.ListMateri, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateMateri = async (id, data) => {
  return await axios.post(API.ListMateri + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListAttendance = async (id, row) => {
  return await axios.get(API.ListAttendance + '?row=' + row + '&training_class=' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteAttendance = async (id) => {
  return await axios.delete(API.ListAttendance + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateAttendance = async (data) => {
  return await axios.post(API.ListAttendance, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateAttendance = async (id, data) => {
  return await axios.post(API.ListAttendance + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SendPasswordAttendance = async (id, data) => {
  return await axios.post(API.ListAttendance + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}


export const ListSchedule = async (row) => {
  return await axios.get(API.ListSchedule + '?row=' + row, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteSchedule = async (id) => {
  return await axios.delete(API.ListSchedule + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateSchedule = async (data) => {
  return await axios.post(API.ListSchedule, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateSchedule = async (id, data) => {
  return await axios.post(API.ListSchedule + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListModulBySchedule = async (id, row) => {
  return await axios.get(API.ListModulBySchedule + '?row=' + row + '&trainingschedule=' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateModulBySchedule = async (data) => {
  return await axios.post(API.ListModulBySchedule, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteModulBySchedule = async (id) => {
  return await axios.delete(API.ListModulBySchedule + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListAbsence = async (id) => {
  return await axios.get(API.ListAbsence + '?training_schedule=' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const PostAbsence = async (form) => {
  return await axios.post(API.ListAbsence, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListScheduleByClass = async (id, row) => {
  return await axios.get(API.ListSchedule + '?row=' + row + '&training_class=' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const Note = async () => {
  return await axios.get(API.note, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateNote = async (form) => {
  return await axios.post(API.note, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const LogActivity = async () => {
  return await axios.get(API.LogActivity, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateLogActivity = async (form) => {
  return await axios.post(API.LogActivity, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListQuestion = async (id, row) => {
  return await axios.get(API.Question + '?row=' + row + '&modul=' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateQuestion = async (id, data) => {
  return await axios.post(API.Question + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const CreateQuestion = async (form) => {
  return await axios.post(API.Question, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteQuestion = async (id) => {
  return await axios.delete(API.Question + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListOption = async (id, row) => {
  return await axios.get(API.Option + '?row=' + row + '&question=' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateOption = async (form) => {
  return await axios.post(API.Option, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteOption = async (id) => {
  return await axios.delete(API.Option + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SetCorrectOption = async (form) => {
  return await axios.post(API.Option + "/set-answer", form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateOption = async (id, data) => {
  return await axios.post(API.Option + "/" + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListResult = async (id, row) => {
  return await axios.get(API.Result + '?row=' + row + '&training_class=' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateResult = async (form) => {
  return await axios.post(API.Result, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const LogResult = async (idUser, idTraining, IdModul) => {
  return await axios.get(API.Result + '/' + idUser + '?modul=' + IdModul + '&training_class=' + idTraining, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListFeedback = async (row) => {
  return await axios.get(API.Feedback + '?row=' + row, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProgressMateri = async (data) => {
  return await axios.post(API.ProgressMateri, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}