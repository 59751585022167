import React, { useEffect, useState } from 'react';
import { Table, Space, Button, Modal, Checkbox } from 'antd';
import Header from '../../Components/HeaderAdmin'
import { ListClass, ListScheduleByClass, PostAbsence, ListAbsence } from '../../Configs/Api'

export default function TrainingAbsence() {
  const [modalSchedule, setModalSchedule] = useState(false)
  const [modalAbsence, setModalAbsence] = useState(false)
  const [idSchedule, setIdSchedule] = useState(false)
  const [idClass, setIdClass] = useState(false)
  const [dataClass, setDataClass] = useState([])
  const [dataAbsence, setDataAbsence] = useState([])
  const [dataSchedule, setDataSchedule] = useState([])

  useEffect(() => {
    getListClass()
  }, []);

  const getListAbsence = async (id) => {
    const data = await ListAbsence(id)
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "absence": data.absence,
          "name": data.name,
          "id": data.id,
          "Data": data,
        })
      })
      setDataAbsence(array)
    }
  }
  const getListClass = async () => {
    const data = await ListClass('', '0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "title": data.title,
          "start": data.start_date,
          "end": data.end_date,
          "location": data.location,
          "id_location": data.id_location,
          "trainer": data.trainer,
          "id_trainer": data.id_trainer,
          "id": data.id,
          "Data": data,
        })
      })
      setDataClass(array)
    }
  }
  const getListSchedule = async (id) => {
    const data = await ListScheduleByClass(id, '0')
    if (data.status) {
      const array = []
      data.data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "class": data.title,
          "schedule": data.date,
          "trainer": data.trainer,
          "idTrainer": data.id_training_class,
          "location": data.location,
          "id": data.id,
          "Data": data,
        })
      })
      setDataSchedule(array)
    }
  }
  const viewAbsence = (id) => {
    setModalAbsence(!modalAbsence)
    setIdSchedule(id)
    getListAbsence(id)
  }
  const viewSchedule = (id) => {
    setModalSchedule(!modalSchedule)
    setIdClass(id)
    getListSchedule(id)
  }

  const handleCheck = (e, id) => {
    const value = e.target.checked
    const Id = id
    const elementsIndex = dataAbsence.findIndex(element => element.id === Id)
    let newArray = [...dataAbsence]
    newArray[elementsIndex] = { ...newArray[elementsIndex], "absence": value }
    setDataAbsence(newArray)
  }
  const save = async () => {
    var formData = new FormData();
    formData.append('training_schedule', idSchedule);
    formData.append('training_class', idClass);
    formData.append('data', JSON.stringify(dataAbsence));
    const data = await PostAbsence(formData)
    if (data.status) {
      setModalAbsence(false)
    }
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      render: text => <a>{text}</a>,
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Trainer',
      dataIndex: 'trainer',
      key: 'trainer',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <Button onClick={() => viewSchedule(Data.id)} type="primary">View</Button>
        </Space>
      ),
    },
  ];
  const columnsSchedule = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Class',
      dataIndex: 'class',
      key: 'class',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Schedule',
      dataIndex: 'schedule',
      key: 'schedule',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <Button onClick={() => viewAbsence(Data.id)} type="primary">Absence</Button>
        </Space>
      ),
    },
  ];

  const columnsAbsence = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Absence',
      dataIndex: 'absence',
      key: 'absence',
      align: 'center',
      render: (absence, Data) => {
        return (
          <div>
            <Checkbox checked={absence} onChange={e => handleCheck(e, Data.id)} />
          </div>
        )
      }
    },
  ];
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full  bg-white shadow-md p-3">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'> Daftar Training Class</div>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <Table
                columns={columns}
                dataSource={dataClass}
                scroll={{ x: 1000, y: 500 }}
                pagination={true}
                bordered
              />
            </div>
          </div>
          <Modal title="Training Schedule" width={1000} visible={modalSchedule} onOk={e => setModalSchedule(false)} onCancel={e => setModalSchedule(false)}>
            <Table
              columns={columnsSchedule}
              dataSource={dataSchedule}
              pagination={true}
              bordered
            />
          </Modal>
          <Modal title="List of Attendees" width={1000} visible={modalAbsence} onOk={e => save()} onCancel={e => setModalAbsence(false)}>
            <Table
              columns={columnsAbsence}
              dataSource={dataAbsence}
              pagination={true}
              bordered
            />
          </Modal>

        </div>
      </div>
    </>
  )
}
