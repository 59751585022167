import React from "react";
import { Link } from "react-router-dom";


const ProgressContent = ({ data }) => {

    const handleLihatModul = (data) => {
        localStorage.setItem('idModul', btoa(data.id));
        localStorage.setItem('TrainingClass', btoa(data.id_training_class));
    }
    
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-28">
            <h3 className="text-center text-3xl mb-12">My Progress</h3>
            <div className="grid md:grid-cols-2 gap-4">
                {data.map((item, idx) => 
                item.percentage > 0 ?
                <div className="flex flex-col lg:flex-row border rounded-xl p-4" key={idx}>
                    <div className="flex gap-2 grow">
                        <img src={item.icon} width="96px" height="96px" />
                        <div className="flex flex-col w-8/12">
                            <p className="text-base font-semibold mb-2">{idx+1}. {item.name}</p>
                            <div className='flex items-center mb-2 gap-2'>
                                <div className="w-full bg-gray-200 rounded-full h-1.5 mb-0 dark:bg-gray-700">
                                    <div className="bg-orange-400 h-1.5 rounded-full" style={{width: item.percentage+"%"}}></div>
                                </div>
                                <p className='text-base mb-0 text-orange-400'>{item.percentage}%</p>
                            </div>
                            <p>Nilai Quiz: <b>{idx === 0 && "No Quiz"}{item.nilai > 0 && item.nilai}{(!item.nilai && idx !== 0) && "Belum mengerjakan"}</b> {idx > 0 && "/ 100"}</p>
                        </div>
                    </div>
                    <Link to={item.path} className="self-center whitespace-nowrap lg:self-start p-2 rounded-xl text-white hover:text-black" style={{backgroundColor:"rgb(23, 169, 226)"}} onClick={() => handleLihatModul(item)}>Lihat Modul</Link>
                </div>
                :
                <div key={idx}></div>
                )}
            </div>
        </div>
    )
}

export default ProgressContent