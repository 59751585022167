const TOKEN_KEY = 'TOKEN';
const ACCESS = 'ACCESS_USER';

export const login = (data) => {
  localStorage.setItem('Menu', JSON.stringify(data.data.modul))
  localStorage.setItem('User', JSON.stringify(data.data.user))
  localStorage.setItem(TOKEN_KEY, btoa(data.data.access_token));
  localStorage.setItem(ACCESS, btoa(data.data.user.role));
  return { 'login': true }
}

export const logout = () => {
  localStorage.clear();
  window.location.reload()
}

export const isLogin = () => {
  if (atob(localStorage.getItem(TOKEN_KEY)) && atob(localStorage.getItem(ACCESS)) === 'participant') {
    return { 'login': true, 'role': 'participant' }
  }
  else if (atob(localStorage.getItem(TOKEN_KEY)) && atob(localStorage.getItem(ACCESS)) === 'trainer') {
    return { 'login': true, 'role': 'trainer' }
  }
  return false;
}