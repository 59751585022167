import React, { useEffect, useState, Fragment, useRef } from 'react';
import { ExclamationIcon, PencilIcon, TrashIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Table, Space, Form, Input, Button, DatePicker, Modal, Select } from 'antd';
import Header from '../../Components/HeaderAdmin'
import { ListClass, CreateClass, DeleteClass, UpdateClass, ListUser, ListLocation } from '../../Configs/Api'
import moment from 'moment';

export default function TrainingClass() {
  const [Delete, setDelete] = useState(false)
  const [Add, setAdd] = useState(false)
  const [EditClass, setEditClass] = useState(false)
  const [dataClass, setDataClass] = useState([])
  const [idDeleteClass, setIdClassDelete] = useState([])
  const [dataTrainer, setDataTrainer] = useState([])
  const [dataLocation, setDataLocation] = useState([])
  const [dataCreateClass, setDataCreateClass] = useState({
    titleCreate: '',
    startCreate: '',
    endCreate: '',
    locationCreate: '',
    trainerCreate: ''
  })
  const [dataUpdateClass, setDataUpdateClass] = useState({
    id: '',
    title: '',
    start: '',
    end: '',
    location: '',
    trainer: ''
  })
  const cancelButtonRef = useRef(null)
  const { Option } = Select;
  const { id, title, start, end, location, trainer } = dataUpdateClass
  const updateDataClass = (data) => setDataUpdateClass((state) => ({ ...state, ...data }));
  const { titleCreate, startCreate, endCreate, locationCreate, trainerCreate } = dataCreateClass
  const createDataClass = (data) => setDataCreateClass((state) => ({ ...state, ...data }));

  useEffect(() => {
    getListClass()
  }, []);

  const getListClass = async () => {
    const data = await ListClass('', '0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "title": data.title,
          "start": data.start_date,
          "end": data.end_date,
          "location": data.location,
          "id_location": data.id_location,
          "trainer": data.trainer,
          "id_trainer": data.id_trainer,
          "id": data.id,
          "Data": data,
        })
      })
      setDataClass(array)
    }
  }
  const getListLocation = async () => {
    const data = await ListLocation('0')
    if (data.status) {
      setDataLocation(data.data.data)
    }
  }
  const getListUser = async () => {
    const data = await ListUser('trainer', '0')
    if (data.status) {
      setDataTrainer(data.data.data)
    }
  }
  const handleDeleteClass = async () => {
    var Id = idDeleteClass
    const data = await DeleteClass(Id)
    if (data.status) {
      setIdClassDelete('')
      getListClass()
      setDelete(!Delete)
    }
  }
  const deleteClass = (id) => {
    setIdClassDelete(id)
    setDelete(!Delete)
  }

  const createClass = async () => {
    var formData = new FormData();
    formData.append('title', titleCreate);
    formData.append('location', locationCreate);
    formData.append('trainer', trainerCreate);
    formData.append('start_date', startCreate);
    formData.append('end_date', endCreate);
    const data = await CreateClass(formData)
    if (data.status) {
      getListClass()
      createDataClass({
        titleCreate: '',
        startCreate: '',
        endCreate: '',
        locationCreate: '',
        trainerCreate: ''
      })
      setAdd(!Add)
    }
  }
  const updateClass = async () => {
    var Id = id
    var formData = new FormData();
    formData.append('title', title);
    formData.append('location', location);
    formData.append('trainer', trainer);
    formData.append('start_date', start);
    formData.append('end_date', end);
    formData.append('_method', 'PUT');
    const data = await UpdateClass(Id, formData)
    if (data.status) {
      getListClass()
      updateDataClass({
        id: '',
        title: '',
        start: '',
        end: '',
        location: '',
        trainer: ''
      })
      setEditClass(!EditClass)
    }
  }
  const handleAddClass = () => {
    getListLocation()
    getListUser()
    setAdd(!Add)
  }
  const handleEditClass = (data) => {
    updateDataClass({
      id: data.id,
      title: data.title,
      start: data.start,
      end: data.end,
      location: data.id_location,
      trainer: data.id_trainer
    })
    getListLocation()
    getListUser()
    setEditClass(!EditClass)
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      render: text => <a>{text}</a>,
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Trainer',
      dataIndex: 'trainer',
      key: 'trainer',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <PencilIcon onClick={() => handleEditClass(Data)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          <TrashIcon onClick={() => deleteClass(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
        </Space>
      ),
    },
  ];
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full  bg-white shadow-md p-3">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'> Daftar Training Class</div>
            <Button type="primary" onClick={() => handleAddClass()}>Tambah</Button>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <Table
                columns={columns}
                dataSource={dataClass}
                scroll={{ x: 1000, y: 500 }}
                pagination={true}
                bordered
              />
            </div>
          </div>
          <Transition.Root show={Delete} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setDelete}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Delete Training Class
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete this Class ? All of your data will be permanently removed.
                              This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => handleDeleteClass()}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setDelete(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
          <Modal title="Ubah Class" visible={EditClass} onOk={e => updateClass()} onCancel={e => setEditClass(false)}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Title">
                <Input value={title} onChange={e => updateDataClass({ title: e.target.value })} placeholder="input placeholder" />
              </Form.Item>
              <Form.Item name="date-picker" label="Start Class" >
                <DatePicker defaultValue={moment(start, 'YYYY/MM/DD')} onChange={(date, dateString) => updateDataClass({ start: dateString })} />
              </Form.Item>
              <Form.Item label="End Class">
                <DatePicker defaultValue={moment(end, 'YYYY/MM/DD')} onChange={(date, dateString) => updateDataClass({ end: dateString })} />
              </Form.Item>
              <Form.Item label="Location">
                <Select
                  value={location}
                  onChange={e => updateDataClass({ location: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {dataLocation.length > 0 ?
                    dataLocation.map((data, idx) => (
                      <Option key={idx} value={data.id}>{data.name}</Option>
                    ))
                    :
                    null
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Trainer">
                <Select
                  value={trainer}
                  onChange={e => updateDataClass({ trainer: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {dataTrainer.length > 0 ?
                    dataTrainer.map((data, idx) => (
                      <Option key={idx} value={data.id}>{data.name}</Option>
                    ))
                    :
                    null
                  }
                </Select>
              </Form.Item>
            </Form>
          </Modal>
          <Modal title="Tambah Class" visible={Add} onOk={e => createClass()} onCancel={e => setAdd(false)}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Title">
                <Input value={titleCreate} onChange={e => createDataClass({ titleCreate: e.target.value })} placeholder="input placeholder" />
              </Form.Item>
              <Form.Item name="date-picker" label="Start Class" >
                <DatePicker onChange={(date, dateString) => createDataClass({ startCreate: dateString })} />
              </Form.Item>
              <Form.Item label="End Class">
                <DatePicker onChange={(date, dateString) => createDataClass({ endCreate: dateString })} />
              </Form.Item>
              <Form.Item label="Location">
                <Select
                  value={locationCreate}
                  onChange={e => createDataClass({ locationCreate: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {dataLocation.length > 0 ?
                    dataLocation.map((data, idx) => (
                      <Option key={idx} value={data.id}>{data.name}</Option>
                    ))
                    :
                    null
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Trainer">
                <Select
                  value={trainerCreate}
                  onChange={e => createDataClass({ trainerCreate: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {dataTrainer.length > 0 ?
                    dataTrainer.map((data, idx) => (
                      <Option key={idx} value={data.id}>{data.name}</Option>
                    ))
                    :
                    null
                  }
                </Select>
              </Form.Item>
            </Form>
          </Modal>

        </div>
      </div>
    </>
  )
}
