import React, { useEffect, useState } from 'react';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import Header from '../../Components/HeaderAdmin'
import { Table, Space, Tooltip, Button, Modal, Select, Form, DatePicker } from 'antd';
import { ListModul, ListSchedule, CreateSchedule, DeleteSchedule, ListClass, ListModulBySchedule, DeleteModulBySchedule, CreateModulBySchedule, UpdateSchedule } from '../../Configs/Api'
import moment from 'moment';

const { Option } = Select;

export default function TrainingSchedule() {
  const [dataModul, setDataModul] = useState([])
  const [dataSchedule, setDataSchedule] = useState([])
  const [dataClass, setDataClass] = useState([])
  const [listModul, setListModul] = useState([])
  const [modalSchedule, setModalSchedule] = useState(false)
  const [modalModul, setModalModul] = useState(false)
  const [modalEditSchedule, setModalEditSchedule] = useState(false)
  const [date, setDate] = useState('')
  const [idModul, setIdModul] = useState('')
  const [idClassUpdate, setIdClassUpdate] = useState('')
  const [dateUpdate, setDateUpdate] = useState('')
  const [idClass, setIdClass] = useState('')
  const [idSchedule, setIdSchedule] = useState('')
  const [idScheduleUpdate, setIdScheduleUpdate] = useState('')

  useEffect(() => {
    getListSchedule()
  }, []);

  const getListModul = async () => {
    const data = await ListModul('0')
    if (data.status) {
      setDataModul(data.data.data)
    }
  }
  const getListSchedule = async () => {
    const data = await ListSchedule('')
    if (data.status) {
      const array = []
      data.data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "title": data.title,
          "date": data.date,
          "trainer": data.trainer,
          "idTrainer": data.id_training_class,
          "location": data.location,
          "id": data.id,
          "Data": data,
        })
      })
      setDataSchedule(array)
    }
  }

  const getListModulBySchedule = async (ID) => {
    const id = ID
    const data = await ListModulBySchedule(id, '')
    if (data.status) {
      const array = []
      data.data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "modul": data.modul,
          "id": data.id,
          "Data": data,
        })
      })
      setListModul(array)
    }
  }
  const deleteModul = async (id) => {
    const data = await DeleteModulBySchedule(id)
    if (data.status) {
      getListModulBySchedule(idSchedule)
    }
  }
  const deleteSchedule = async (id) => {
    const data = await DeleteSchedule(id)
    if (data.status) {
      getListSchedule()
    }
  }

  const handleAddModul = async () => {
    var formData = new FormData();
    formData.append('training_schedule', idSchedule);
    formData.append('modul', idModul);
    const data = await CreateModulBySchedule(formData)
    if (data.status) {
      setIdModul('')
      getListModulBySchedule(idSchedule)
    }
  }
  const handleAddSchedule = async () => {
    var formData = new FormData();
    formData.append('training_class', idClass);
    formData.append('date', date);
    const data = await CreateSchedule(formData)
    if (data.status) {
      setModalSchedule(!modalSchedule)
      getListSchedule()
      setIdClass('')
      setDate('')
    }
  }

  const cancelEditSchedule = async () => {
    setModalEditSchedule(!modalEditSchedule)
    setIdClassUpdate('')
    setDateUpdate('')
    setIdScheduleUpdate('')
    getListSchedule()
  }
  const handleEditSchedule = async () => {
    var Id = idScheduleUpdate
    var formData = new FormData();
    formData.append('training_class', idClassUpdate);
    formData.append('date', dateUpdate);
    formData.append('_method', 'PUT');
    const data = await UpdateSchedule(Id, formData)
    if (data.status) {
      setModalEditSchedule(!modalEditSchedule)
      setIdClassUpdate('')
      setDateUpdate('')
      setIdScheduleUpdate('')
      getListSchedule()
    }
  }
  const editSchedule = (data) => {
    setIdClassUpdate(data.idTrainer)
    setDateUpdate(data.date)
    setIdScheduleUpdate(data.id)
    setModalEditSchedule(!modalEditSchedule)
    getListClass()
  }
  const addModul = (id) => {
    if (id > 0) {
      setIdSchedule(id)
      setModalModul(!modalModul)
      getListModul()
      getListModulBySchedule(id)
    } else {
      setIdModul('')
      setIdSchedule('')
      setModalModul(!modalModul)
    }
  }
  const addSchedule = () => {
    setModalSchedule(!modalSchedule)
    getListClass()
  }
  const getListClass = async () => {
    const data = await ListClass('0', '')
    if (data.status) {
      setDataClass(data.data.data)
    }
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Head Trainer',
      dataIndex: 'trainer',
      key: 'trainer',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <Tooltip placement="top" title={'View'}>
            <EyeIcon onClick={() => addModul(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
          <Tooltip placement="top" title={'Edit'}>
            <PencilIcon onClick={() => editSchedule(Data)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
          <Tooltip placement="top" title={'Delete'}>
            <TrashIcon onClick={() => deleteSchedule(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const columnsModul = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Modul',
      dataIndex: 'modul',
      key: 'modul',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <Tooltip placement="top" title={'Delete'}>
            <TrashIcon onClick={() => deleteModul(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full h-full bg-white shadow-md p-3">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'> Daftar schedule</div>
            <Button type="primary" onClick={() => addSchedule()}>Tambah</Button>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <Table
                columns={columns}
                dataSource={dataSchedule}
                scroll={{ x: 1000, y: 500 }}
                pagination={true}
                bordered
              />
            </div>
          </div>
        </div>
      </div>
      <Modal title="Tambah Modul" width={1000} visible={modalModul} onOk={() => addModul()} onCancel={() => addModul()}>
        <div>
          <Select
            className='w-full'
            value={idModul}
            onChange={e => setIdModul(e)}
            showSearch
            placeholder="Search to Select"
            optionFilterProp="children"
          >
            {dataModul.length > 0 ?
              dataModul.map((data, idx) => (
                <Option key={idx} value={data.id}>{data.name}</Option>
              ))
              :
              null
            }
          </Select>
        </div>
        <div className='my-2'><Button type="primary" onClick={() => handleAddModul()}>Tambah</Button></div>
        <div>
          <Table
            columns={columnsModul}
            dataSource={listModul}
            pagination={true}
            bordered
          />
        </div>
      </Modal>
      <Modal title="Tambah Schedule" visible={modalSchedule} onOk={() => handleAddSchedule()} onCancel={() => addSchedule()}>
        <Form
          layout="vertical"
        >
          <Form.Item label="Training Class">
            <Select
              className='w-full'
              value={idClass}
              onChange={e => setIdClass(e)}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {dataClass.length > 0 ?
                dataClass.map((data, idx) => (
                  <Option key={idx} value={data.id}>{data.title}</Option>
                ))
                :
                null
              }
            </Select>
          </Form.Item>
          <Form.Item label="Date">
            <DatePicker className='w-full' onChange={(date, dateString) => setDate(dateString)} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="Edit Schedule" visible={modalEditSchedule} onOk={() => handleEditSchedule()} onCancel={() => cancelEditSchedule()}>
        <Form
          layout="vertical"
        >
          <Form.Item label="Training Class">
            <Select
              className='w-full'
              value={idClassUpdate}
              onChange={e => setIdClassUpdate(e)}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {dataClass.length > 0 ?
                dataClass.map((data, idx) => (
                  <Option key={idx} value={data.id}>{data.title}</Option>
                ))
                :
                null
              }
            </Select>
          </Form.Item>
          <Form.Item label="Date">
            <DatePicker className='w-full' value={moment(dateUpdate, 'YYYY/MM/DD')} onChange={(date, dateString) => setDateUpdate(dateString)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
