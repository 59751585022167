import React, { useRef } from "react";
import Top from '../../Assets/reviews-top.svg';
import UserImg from '../../Assets/review-user.svg';
import Slider from "react-slick";
import ReviewsCard from "./ReviewsCard";

const Reviews = () => {

    const dataTestimoni = [
        {
          id: 1,
          image: UserImg,
          rating: 4,
          testimoni: "Aplikasinya sangat bagus. Jadi lebih mudah dalam belajar di kantor dan di rumah.",
          nama: "Job Title - Office Branch"
        },
        {
          id: 2,
          image: UserImg,
          rating: 5,
          testimoni: "Aplikasinya sangat bagus. Jadi membantu saya untuk mencari materi yang belum saya pahami.",
          nama: "Job Title - Office Branch"
        },
        {
          id: 3,
          rating: 4,
          image: UserImg,
          testimoni: "Aplikasinya lumayan lengkap. Jadi membantu saya untuk mencari tahu tentang company ini.",
          nama: "Job Title - Office Branch"
        }
      ];
    
    const slider = useRef();

    const next = () => {
        slider.current.slickNext();
    };
    
    const prev = () => {
        slider.current.slickPrev();
    }; 
    
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        arrows: true,
        centerPadding: "10%",
        responsive: [
            {
            breakpoint: 768,
            settings: {
                centerPadding: "0"
            }
            }
        ],
        speed: 500,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3500
    };

    return (
        <div className="flex flex-col items-center">
            <img className="mb-8 w-full h-fit relative -top-1" src={Top} alt="reviews top"/>
            <div>
                <h2 className="text-2xl lg:text-5xl">Reviews</h2>
            </div>
            <Slider ref={slider} {...settings} className="custom-slick-review w-4/6 mb-20">
                {dataTestimoni.map(datum => <ReviewsCard key={datum.id} image={datum.image} rating={datum.rating} testimoni={datum.testimoni} identitas={datum.nama}/>)}
            </Slider>
        </div>
    )
}

export default Reviews