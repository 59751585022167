import React from "react";
import Logo from '../Assets/brand/apple-icon-120x120 copy.png'
function Footers() {
  return (
    <>
      <div className=" bg-gray-800">
        <div className="flex flex-col container">
          <div className="flex-1 flex-row">
            <div
              style={{ backgroundImage: `url(${Logo})` }}
              className="h-20 w-20 bg-cover bg-center"
            />
          </div>
          <div className="line-bottom-gray my-4"></div>
          <div className="text-black grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 md:items-center">
            <div className="text-sm text-base md:text-left lg:text-left  text-center text-white f-f-l w-full">
              <div> © 2022 Loan Market Indonesia</div>
            </div>
            <div className="my-6 text-white text-color f-f-l">
              <ul className="grid grid-cols-1  lg:grid-cols-3 md:grid-cols-3 items-center">
                <li className="cursor-pointer my-2 mx-auto md:mx-0"><a className="float-right" style={{ display: "table-cell" }} href="https://www.loanmarket.co.id/kebijakan-privasi" target="_blank">Kebijakan Privasi</a></li>
                <li className="cursor-pointer my-2 mx-auto"><a style={{ display: "table-cell" }} href="https://www.loanmarket.co.id/syarat-ketentuan" target="_blank">Syarat dan Ketentuan</a></li>
                <li className="cursor-pointer my-2 mx-auto md:mx-0"><a className="float-left" style={{ display: "table-cell" }} href="https://www.loanmarket.co.id/syarat-ketentuan" target="_blank">Complaints & Feedback</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footers;
