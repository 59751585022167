import React from "react";
// import BgMenu from '../../Assets/accelerate-jumborton-bg.png';
import BgMenu from '../../Assets/accelerate-jumborton-bg2.png';
import JumbotronLogo from '../../Assets/accelerate-jumborton-logo.svg';

const Jumbotron = () => {
    return (
    <div className="h-fit">
      <section className="h-full">
        <div className="w-full h-full relative pb-40 pt-20 md:pt-24">
          <img className="absolute md:w-full inset-0 h-full object-cover object-center" src={BgMenu} alt="we care family" />
          <div className="lg:flex items-center relative z-10">
            <div className="flex flex-col items-center w-full px-4">
              <img className="inset-0 object-center mb-8" src={JumbotronLogo} alt="we care family" />
              <h1 tabIndex="0" className="text-indigo-50 text-2xl lg:text-5xl font-bold mb-8 md:mb-20">Fast track your success</h1>
              <p tabIndex="0" className="text-indigo-50 text-xl md:text-2xl mb-4 text-center md:px-16 max-w-screen-lg">
                Accelerate is an online training platform designed by Loan Market Indonesia to prepare you with the necessary skills and knowledge to hit the ground running when you join us. Through this platform, we'll help you in becoming brokers who are relentless about delivering exceptional customer outcomes, who seek innovation in technology to create seamless client experiences and who want to offer more choice.  
                Let's continue to grow Loan Market Indonesia together!
              </p>
            </div>
              {/* <Player
                className="w-full"
                poster="https://www.loanmarket.co.id/api_accelerate/public/img/thumbnail/video-thumbnail.jpg"
                src="https://loanmarket.co.id/api_accelerate/public/video/accelerate.mp4"
              /> */}
          </div>
        </div>
      </section>
    </div>
    )
}

export default Jumbotron