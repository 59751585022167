import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../Utils/Index';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      isLogin().login && isLogin().role === 'participant' || isLogin().role === 'trainer' ?
        <Component {...props} />
        : <Redirect to="/login" />
    )} />
  );
};

export default PrivateRoute;