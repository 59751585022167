import React, { useEffect, useState } from 'react';

import { MailIcon, TrashIcon } from '@heroicons/react/outline'
import Header from '../../Components/HeaderAdmin'
import { Table, Space, Tooltip, Button, Modal, Select, message } from 'antd';
import { ListAttendance, ListClass, ListUser, CreateAttendance, DeleteAttendance, SendPasswordAttendance } from '../../Configs/Api'

const { Option } = Select;

export default function TrainingClass() {
  const [dataClass, setDataClass] = useState([])
  const [dataUser, setDataUser] = useState([])
  const [dataAttendance, setDataAttendance] = useState([])
  const [idUser, setIdUser] = useState('')
  const [addAttendance, setAddAttendance] = useState(false)
  const [idClass, setIdClass] = useState('')

  useEffect(() => {
    getListClass()
  }, []);

  const getListClass = async () => {
    const data = await ListClass('', '0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "title": data.title,
          "start": data.start_date,
          "end": data.end_date,
          "location": data.location,
          "id_location": data.id_location,
          "trainer": data.trainer,
          "id_trainer": data.id_trainer,
          "id": data.id,
          "Data": data,
        })
      })
      setDataClass(array)
    }
  }
  const sendPassword = async (id) => {
    var formData = new FormData();
    formData.append('_method', 'PUT');
    const data = await SendPasswordAttendance(id, formData)
    if (data.status) {
      message.success('password has been changed')
    }
  }
  const getListAttendance = async (id) => {
    const data = await ListAttendance(id, '0')
    if (data.status) {
      console.log(data);
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "nama": data.trainee,
          "id_trainee": data.id_trainee,
          "id": data.id,
          "Data": data,
        })
      })
      setDataAttendance(array)
    }
  }

  const getListUser = async () => {
    const data = await ListUser('0', '')
    if (data.status) {
      setDataUser(data.data.data)
    }
  }
  const deleteAttendance = async (id) => {
    const data = await DeleteAttendance(id)
    if (data.status) {
      getListAttendance(idClass)
    }
  }
  const handleAddAttendance = async () => {
    var formData = new FormData();
    formData.append('training_class', idClass);
    formData.append('trainee', idUser);
    const data = await CreateAttendance(formData)
    if (data.status) {
      getListAttendance(idClass)
      setIdUser('')
    }
  }
  const AddAttendance = (id) => {
    if (id > 0) {
      getListAttendance(id)
      setIdClass(id)
      getListUser()
      setAddAttendance(!addAttendance)
    } else {
      setIdClass('')
      setAddAttendance(!addAttendance)
    }
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      render: text => <a>{text}</a>,
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Trainer',
      dataIndex: 'trainer',
      key: 'trainer',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <Button type='primary' onClick={() => AddAttendance(Data.id)} >View</Button>
        </Space>
      ),
    },
  ];

  const columnsUser = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Nama',
      dataIndex: 'nama',
      key: 'nama',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <Tooltip placement="top" title={'Send Password'}>
            <MailIcon onClick={() => sendPassword(Data.id_trainee)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
          <Tooltip placement="top" title={'Delete'}>
            <TrashIcon onClick={() => deleteAttendance(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full h-full bg-white shadow-md p-3">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'>Daftar Training Attendance</div>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <Table
                columns={columns}
                dataSource={dataClass}
                scroll={{ x: 1000, y: 500 }}
                pagination={true}
                bordered
              />
            </div>
          </div>
        </div>
      </div>
      <Modal title="Class Attendance" width={1000} visible={addAttendance} onOk={() => AddAttendance('')} onCancel={() => AddAttendance('')}>
        <div className='my-2'>
          <Select
            className='w-full'
            value={idUser}
            onChange={e => setIdUser(e)}
            showSearch
            placeholder="Search to Select"
            optionFilterProp="children"
          >
            {dataUser.length > 0 ?
              dataUser.map((data, idx) => (
                <Option key={idx} value={data.id}>{data.name}</Option>
              ))
              :
              null
            }
          </Select>
        </div>
        <div className='my-2'><Button type="primary" onClick={e => handleAddAttendance()}>Add Attendance</Button></div>
        <div>
          <Table
            columns={columnsUser}
            dataSource={dataAttendance}
            pagination={true}
            bordered
          />
        </div>
      </Modal>
    </>
  )
}
