import React, { useEffect, useState } from 'react';
import Header from '../../Components/HeaderAdmin'
import { Table, Space, Button, Modal, Select, Form, Input, Tooltip } from 'antd';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { UpdateOption, SetCorrectOption, CreateOption, DeleteQuestion, ListQuestion, CreateQuestion, ListModul, UpdateQuestion, ListOption, DeleteOption } from '../../Configs/Api'
const { Option } = Select;

export default function Question() {
  const [dataQuestion, setDataQuestion] = useState([])
  const [dataOption, setDataOption] = useState([])
  const [dataModul, setDataModul] = useState([])
  const [modalOption, setModalOption] = useState(false)
  const [modalQuestionEdit, setModalQuestionEdit] = useState(false)
  const [modalOptionEdit, setModalOptionEdit] = useState(false)
  const [modalAddOption, setModalAddOption] = useState(false)
  const [modalQuestion, setModalQuestion] = useState(false)
  const [questionEdit, setQuestionEdit] = useState('')
  const [questionAdd, setQuestionAdd] = useState('')
  const [optionNew, setOptionNew] = useState('')
  const [optionUpdate, setOptionUpdate] = useState('')
  const [modulAdd, setModulAdd] = useState('')
  const [modulEdit, setModulEdit] = useState('')
  const [idQuestionEdit, setIdQuestionEdit] = useState('')
  const [idQuestionOption, setIdQuestionOption] = useState('')
  const [questionOption, setQuestionOption] = useState('')
  const [idOptionUpdate, setIdOptionUpdate] = useState('')

  useEffect(() => {
    getListQuestion()
  }, []);

  const getListQuestion = async () => {//untuk get seluruh pertanyaan
    const data = await ListQuestion('', '0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "modul": data.modul,
          "idModul": data.id_modul,
          "question": data.question,
          "id": data.id,
          "Data": data,
        })
      })
      setDataQuestion(array)
    }
  }

  const getListOption = async (Id) => {
    const data = await ListOption(Id, '0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "setting": data.correct_answer,
          "option": data.option,
          "id": data.id,
          "Data": data,
        })
      })
      setDataOption(array)
    }
  }
  const getListModul = async () => {
    const data = await ListModul('0')
    if (data.status) {
      setDataModul(data.data.data)
    }
  }
  const handleOption = (Data) => {
    setQuestionOption(Data.question)
    setIdQuestionOption(Data.id)
    getListOption(Data.id)
    setModalOption(!modalOption)
  }
  const handleAddQuestion = () => {
    getListModul()
    setModalQuestion(!modalQuestion)
    setModulAdd('')
    setQuestionAdd('')
  }
  const handleEditQuestion = (Data) => {
    getListModul()
    setModalQuestionEdit(!modalQuestionEdit)
    setModulEdit(Data.idModul)
    setQuestionEdit(Data.question)
    setIdQuestionEdit(Data.id)
  }
  const handleEditOption = (Data) => {
    setModalOptionEdit(!modalOptionEdit)
    setOptionUpdate(Data.option)
    setIdOptionUpdate(Data.id)
  }


  const createQuestion = async () => {
    var formData = new FormData();
    formData.append('question', questionAdd);
    formData.append('modul', modulAdd);
    const data = await CreateQuestion(formData)
    if (data.status) {
      setModalQuestion(!modalQuestion)
      setModulAdd('')
      setQuestionAdd('')
      getListQuestion()
    }
  }
  const createOption = async () => {
    var formData = new FormData();
    formData.append('question', idQuestionOption);
    formData.append('option', optionNew);
    const data = await CreateOption(formData)
    if (data.status) {
      setOptionNew('')
      setModalAddOption(false)
      getListOption(idQuestionOption)
    }
  }
  const updateOption = async () => {
    var Id = idOptionUpdate
    var formData = new FormData();
    formData.append('question', idQuestionOption);
    formData.append('option', optionUpdate);
    formData.append('_method', 'PUT');
    const data = await UpdateOption(Id, formData)
    if (data.status) {
      setOptionUpdate('')
      setModalOptionEdit(false)
      setIdOptionUpdate('')
      getListOption(idQuestionOption)
    }
  }

  const setCorrectOption = async (Data) => {
    var formData = new FormData();
    formData.append('question', Data.id_question);
    formData.append('option', Data.id);
    const data = await SetCorrectOption(formData)
    if (data.status) {
      getListOption(Data.id_question)
    }
  }
  const updateQuestion = async () => {
    var Id = idQuestionEdit
    var formData = new FormData();
    formData.append('question', questionEdit);
    formData.append('modul', modulEdit);
    formData.append('_method', 'PUT');
    const data = await UpdateQuestion(Id, formData)
    if (data.status) {
      setModalQuestionEdit(!modalQuestionEdit)
      setModulEdit('')
      setQuestionEdit('')
      getListQuestion()
    }
  }

  const deleteQuestion = async (id) => {
    const data = await DeleteQuestion(id)
    if (data.status) {
      getListQuestion()
    }
  }
  const deleteOption = async (id) => {
    const data = await DeleteOption(id)
    if (data.status) {
      getListOption(idQuestionOption)
    }
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Modul',
      dataIndex: 'modul',
      key: 'modul',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        //icon pada field action
        <Space size="middle">
          <Tooltip placement="top" title={'View'}>
            <EyeIcon onClick={() => handleOption(Data)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
          <Tooltip placement="top" title={'Edit'}>
            <PencilIcon onClick={() => handleEditQuestion(Data)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
          <Tooltip placement="top" title={'Delete'}>
            <TrashIcon onClick={() => deleteQuestion(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const columnsOption = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'option',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Setting',
      dataIndex: 'Data',
      key: 'Data',
      align: 'center',
      render: (Data) => {
        if (Data.correct_answer === 0) {
          return (
            <Button onClick={e => setCorrectOption(Data)} type="dashed">Setting jawaban benar</Button>
          )
        } else {
          return (
            <Button onClick={e => setCorrectOption(Data)} type="primary">Jawaban Benar</Button>
          )
        }
      }
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <Tooltip placement="top" title={'Edit'}>
            <PencilIcon onClick={() => handleEditOption(Data)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
          <Tooltip placement="top" title={'Delete'}>
            <TrashIcon onClick={() => deleteOption(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          </Tooltip>
        </Space>
      ),
    },
  ];


  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full h-full bg-white shadow-md p-3">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'>Daftar Question</div>
            <Button type="primary" onClick={() => handleAddQuestion()}>Tambah Question</Button>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <Table
                columns={columns}
                dataSource={dataQuestion}
                scroll={{ x: 1000, y: 500 }}
                pagination={true}
                bordered
              />
            </div>
          </div>
        </div>
      </div>
      <Modal title="New Question" visible={modalQuestion} onOk={() => createQuestion()} onCancel={() => setModalQuestion(false)}>
        <Form
          layout="vertical"
        >
          <Form.Item label="Question">
            <Input.TextArea value={questionAdd} onChange={e => setQuestionAdd(e.target.value)} />
          </Form.Item>
          <Form.Item label="Modul">
            <Select
              value={modulAdd}
              onChange={e => setModulAdd(e)}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {dataModul.length > 0 ?
                dataModul.map((data, idx) => (
                  <Option key={idx} value={data.id}>{data.name}</Option>
                ))
                :
                null
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="Edit Question" visible={modalQuestionEdit} onOk={() => updateQuestion()} onCancel={() => setModalQuestionEdit(false)}>
        <Form
          layout="vertical"
        >
          <Form.Item label="Question">
            <Input.TextArea value={questionEdit} onChange={e => setQuestionEdit(e.target.value)} />
          </Form.Item>
          <Form.Item label="Modul">
            <Select
              value={modulEdit}
              onChange={e => setModulEdit(e)}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {dataModul.length > 0 ?
                dataModul.map((data, idx) => (
                  <Option key={idx} value={data.id}>{data.name}</Option>
                ))
                :
                null
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal width={1000} title="Assesment Option" visible={modalOption} onOk={() => handleOption('')} onCancel={() => handleOption('')}>
        <Button type="primary" className='my-2' onClick={e => setModalAddOption(true)}>New Option</Button>
        <div className='my-2 p-3 bg-gray-200 text-center'>
          <div className='text-sm font-medium'>{questionOption}</div>
        </div>
        <div>
          <Table
            columns={columnsOption}
            dataSource={dataOption}
            pagination={true}
            bordered
          />
        </div>
      </Modal>
      <Modal title="New Option" visible={modalAddOption} onOk={() => createOption()} onCancel={() => setModalAddOption(false)}>
        <Form.Item>
          <Input.TextArea value={optionNew} onChange={e => setOptionNew(e.target.value)} />
        </Form.Item>
      </Modal>
      <Modal title="Update Option" visible={modalOptionEdit} onOk={() => updateOption()} onCancel={() => setModalOptionEdit(false)}>
        <Form.Item>
          <Input.TextArea value={optionUpdate} onChange={e => setOptionUpdate(e.target.value)} />
        </Form.Item>
      </Modal>
    </>
  )
}
