import React, { useState, useEffect } from 'react';
import Header from '../../Components/HeaderAdmin'
import { LogActivity } from '../../Configs/Api';
import { Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';


export default function Dashboard() {
  const [activity, setActivity] = useState([]);
  useEffect(() => {
    getActivity()
  }, [])
  const getActivity = async () => {
    const data = await LogActivity();
    if (data.status) {
      setActivity(data.data.data);
    }
  }
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="w-full bg-white shadow-md p-3">
          <div className='font-bold text-sm text-gray-500 line-bottom-gray py-2'> Aktifitas Peserta Training</div>
          <div className='mt-4'>
            <Timeline>
              {activity.length > 0 ?
                activity.map((data, idx) => (
                  <Timeline.Item key={idx} dot={<ClockCircleOutlined className="timeline-clock-icon" />} color="blue">
                    <div className='font-bold text-gray-500 '>{data.name + ' ' + data.log_name + ' ' + data.modul}</div>
                    <label className='text-blue-300'>{moment(data.created_at).format('L')}</label>
                  </Timeline.Item>
                ))
                :
                null
              }
            </Timeline>,
          </div>
        </div>
      </div>
    </>
  )
}