import React, { useEffect, useState, Fragment, useRef } from 'react';
import { ExclamationIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Table, Space, Form, Input, Button, Modal, Select } from 'antd';
import Header from '../../Components/HeaderAdmin'
import { ListUser, CreateUser, DeleteUser, UpdateUser, ListOffice } from '../../Configs/Api'

export default function Users() {
  const [Delete, setDelete] = useState(false)
  const [Add, setAdd] = useState(false)
  const [EditUser, setEditUser] = useState(false)
  const [idDeleteUser, setIdUserDelete] = useState([])
  const [dataOffice, setDataOffice] = useState([])
  const [dataUser, setDataUser] = useState([])
  const [dataCreateUser, setDataCreateUser] = useState({
    namaCreate: '',
    emailCreate: '',
    phoneCreate: '',
    idOfficeCreate: '',
    roleCreate: ''
  })
  const [dataUpdateUser, setDataUpdateUser] = useState({
    idUpdate: '',
    namaUpdate: '',
    emailUpdate: '',
    phoneUpdate: '',
    idOfficeUpdate: '',
    roleUpdate: ''
  })
  const cancelButtonRef = useRef(null)
  const { Option } = Select;
  const { idUpdate, namaUpdate, emailUpdate, phoneUpdate, idOfficeUpdate, roleUpdate } = dataUpdateUser
  const updateDataUser = (data) => setDataUpdateUser((state) => ({ ...state, ...data }));
  const { namaCreate, emailCreate, phoneCreate, idOfficeCreate, roleCreate } = dataCreateUser
  const createDataUser = (data) => setDataCreateUser((state) => ({ ...state, ...data }));

  useEffect(() => {
    getListUser()
  }, []);

  const getListUser = async () => {
    const data = await ListUser('0', '')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "nama": data.name,
          "kantor": data.office,
          "hp": data.phone,
          "role": data.role,
          "email": data.email,
          "id": data.id,
          "id_office": data.id_office,
          "Data": data,
        })
      })
      setDataUser(array)
    }
  }

  const getListOffice = async () => {
    const data = await ListOffice()
    if (data.status) {
      setDataOffice(data.data.data)
    }
  }
  const handleDeleteUser = async () => {
    var Id = idDeleteUser
    const data = await DeleteUser(Id)
    if (data.status) {
      setIdUserDelete('')
      getListUser()
      setDelete(!Delete)
    }
  }
  const deleteUser = (id) => {
    setIdUserDelete(id)
    setDelete(!Delete)
  }

  const createUser = async () => {
    var formData = new FormData();
    formData.append('name', namaCreate);
    formData.append('email', emailCreate);
    formData.append('id_office', idOfficeCreate);
    formData.append('role', roleCreate);
    formData.append('phone', phoneCreate);
    const data = await CreateUser(formData)
    if (data.status) {
      getListUser()
      createDataUser({
        namaCreate: '',
        emailCreate: '',
        phoneCreate: '',
        idOfficeCreate: '',
        roleCreate: ''
      })
      setAdd(!Add)
    }
  }

  const updateUser = async () => {
    var Id = idUpdate
    var formData = new FormData();
    formData.append('name', namaUpdate);
    formData.append('email', emailUpdate);
    formData.append('phone', phoneUpdate);
    formData.append('role', roleUpdate);
    formData.append('id_office', idOfficeUpdate);
    formData.append('_method', 'PUT');
    const data = await UpdateUser(Id, formData)
    if (data.status) {
      getListUser()
      updateDataUser({
        idUpdate: '',
        namaUpdate: '',
        emailUpdate: '',
        phoneUpdate: '',
        idOfficeUpdate: '',
        roleUpdate: ''
      })
      setEditUser(!EditUser)
    }
  }
  const handleAddClass = () => {
    getListOffice()
    getListUser()
    setAdd(!Add)
  }
  const handleEditUser = (data) => {
    updateDataUser({
      idUpdate: data.id,
      namaUpdate: data.nama,
      emailUpdate: data.email,
      phoneUpdate: data.hp,
      roleUpdate: data.role,
      idOfficeUpdate: data.id_office,
    })
    getListUser()
    setEditUser(!EditUser)
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Nama',
      dataIndex: 'nama',
      key: 'nama',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => <a>{text}</a>,
    },
    {
      title: 'HP',
      dataIndex: 'hp',
      key: 'hp',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Kantor',
      dataIndex: 'kantor',
      key: 'kantor',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <PencilIcon onClick={() => handleEditUser(Data)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          <TrashIcon onClick={() => deleteUser(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
        </Space>
      ),
    },
  ];
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full  bg-white shadow-md p-3 min-h-half">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'> User</div>
            <Button type="primary" onClick={() => handleAddClass()}>Tambah</Button>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <Table
                columns={columns}
                dataSource={dataUser}
                scroll={{ x: 1000, y: 500 }}
                pagination={true}
                bordered
              />
            </div>
          </div>
          <Transition.Root show={Delete} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setDelete}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Delete User
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete this User ? All of your data will be permanently removed.
                              This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => handleDeleteUser()}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setDelete(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
          <Modal title="Ubah User" visible={EditUser} onOk={e => updateUser()} onCancel={e => setEditUser(false)}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Nama">
                <Input value={namaUpdate} onChange={e => updateDataUser({ namaUpdate: e.target.value })} />
              </Form.Item>
              <Form.Item label="Email">
                <Input value={emailUpdate} onChange={e => updateDataUser({ emailUpdate: e.target.value })} />
              </Form.Item>
              <Form.Item label="No.Handphone">
                <Input value={phoneUpdate} onChange={e => updateDataUser({ phoneUpdate: e.target.value })} />
              </Form.Item>
              <Form.Item label="Kantor">
                <Select
                  value={idOfficeUpdate}
                  onChange={e => updateDataUser({ idOfficeUpdate: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {dataOffice.length > 0 ?
                    dataOffice.map((data, idx) => (
                      <Option key={idx} value={data.id}>{data.name}</Option>
                    ))
                    :
                    null
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Role">
                <Select
                  value={roleUpdate}
                  onChange={e => updateDataUser({ roleUpdate: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >

                  <Option value='participant'>participant</Option>
                  <Option value='trainer'>trainer</Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>
          <Modal title="Tambah User" visible={Add} onOk={e => createUser()} onCancel={e => setAdd(false)}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Nama">
                <Input value={namaCreate} onChange={e => createDataUser({ namaCreate: e.target.value })} />
              </Form.Item>
              <Form.Item label="Email">
                <Input value={emailCreate} onChange={e => createDataUser({ emailCreate: e.target.value })} />
              </Form.Item>
              <Form.Item label="No.Handphone">
                <Input value={phoneCreate} onChange={e => createDataUser({ phoneCreate: e.target.value })} />
              </Form.Item>
              <Form.Item label="Kantor">
                <Select
                  value={idOfficeCreate}
                  onChange={e => createDataUser({ idOfficeCreate: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {dataOffice.length > 0 ?
                    dataOffice.map((data, idx) => (
                      <Option key={idx} value={data.id}>{data.name}</Option>
                    ))
                    :
                    null
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Role">
                <Select
                  value={roleCreate}
                  onChange={e => createDataUser({ roleCreate: e })}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >

                  <Option value='participant'>participant</Option>
                  <Option value='trainer'>trainer</Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  )
}
