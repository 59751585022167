import React from 'react'
import { Rate } from 'antd';

const TestimonialCard = ({rating, image, testimoni, identitas}) => {

  return (
    <div className="testimoni__detail flex flex-col md:flex-row items-center shadow-md bg-white">
        <img src={image} alt="test"/>
        <div className="testimoni__detail-right flex flex-col items-center md:items-start">
            <Rate disabled defaultValue={rating} style={{color:"#38bdf8"}}/>
            <p className="testimoni__detail-quote" style={{fontWeight:"bold"}}>"{testimoni}"</p>
            <p className="testimoni__detail-bio">{identitas}</p>
        </div>
    </div>
  )
}

export default TestimonialCard