import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { Password } from "../Configs/Api";

function ChangePassword(props) {
  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation:''
  });
  const { oldPassword, newPassword ,newPasswordConfirmation} = state
  
  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  const updatePassword = async (e) => {
    var bodyFormData = new FormData();
    bodyFormData.set('old_password', oldPassword);
    bodyFormData.set('new_password', newPassword);
    bodyFormData.set('new_password_confirmation', newPasswordConfirmation);
    const data = await Password(bodyFormData)
    if(data.status){
      message.success('change password success')
    }else{
      message.error(data.data.data.new_password ? data.data.data.new_password[0] : data.data.data.old_password[0])
    }
  }
  return (
    <div className="container-menu min-h-1/2 py-4" style={{paddingTop: "3rem"}}>
      <div className="bg-lm py-2 px-4 mt-1 md:mt-4 text-1xl md:text-2xl">Change Password</div>
      {/* Card is full width. Use in 12 col grid for best view. */}
      {/* Card code block start */}
      <div className="flex justify-center items-center">
        <div className="bg-transparent shadow rounded sm:w-full xs:w-full md:w-2/3 p-4 my-6">
            <Form
              layout="vertical"
              name="basic"
              onFinish={updatePassword}
              autoComplete="off"
            >
              <Form.Item
                label="Old Password"
                name="oldPassword"
                rules={[{ required: true, message: 'Please input your Old Password!' }]}
              >
                <Input.Password onChange={e => updateState({ oldPassword: e.target.value })}  size="large" />
              </Form.Item>

              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[{ required: true, message: 'Please input your New password!' }]}
              >
                <Input.Password onChange={e => updateState({ newPassword: e.target.value })}  size="large" />
              </Form.Item>
              <Form.Item
                label="New Password Confirmation"
                name="newPasswordConfirmation"
                rules={[{ required: true, message: 'Please input your New password Confirmation!' }]}
              >
                <Input.Password onChange={e => updateState({ newPasswordConfirmation: e.target.value })}  size="large" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-full mt-6">
                  Change
                </Button>
              </Form.Item>
            </Form>
        </div>
      </div>
      {/* Card code block end */}
    </div >
  );
}
export default ChangePassword;
