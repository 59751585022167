import React, { useEffect, useState } from 'react';
import { Table, Space, Button, Modal, Checkbox, message } from 'antd';
import Header from '../../Components/HeaderAdmin'
import { ListClass, ListScheduleByClass, ListAbsence, ListResult, ListModul, LogResult } from '../../Configs/Api'
import moment from 'moment';

export default function TrainingAbsence() {
  const [modalResult, setModalResult] = useState(false)
  const [modalLog, setModalLog] = useState(false)
  const [idTraining, setIdTraining] = useState('')
  const [dataClass, setDataClass] = useState([])
  const [dataLog, setDataLog] = useState([])
  const [dataSchedule, setDataSchedule] = useState([])
  const [dataModul, setDataModul] = useState([])

  useEffect(() => {
    getListClass()
  }, []);

  const getListClass = async () => {
    const data = await ListClass('', '0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "title": data.title,
          "start": data.start_date,
          "end": data.end_date,
          "location": data.location,
          "id_location": data.id_location,
          "trainer": data.trainer,
          "id_trainer": data.id_trainer,
          "id": data.id,
          "Data": data,
        })
      })
      setDataClass(array)
    }
  }

  const getModul = async () => {
    const data = await ListModul('0')
    if (data.status) {
      const Data = [{ name: 'name' }]
      data.data.data.map((dt, idx) => {
        Data.push({ name: dt.name })
      })
      setDataModul(Data)
    }
  }
  const getListResult = async (id) => {
    setIdTraining(id)
    const data = await ListResult(id, '0')
    console.log(data)
    if (data.status) {
      setDataSchedule(data.data.data)
    }
  }
  const handleLog = (data) => {
    setModalLog(!modalLog)
    Log(data.id_modul, data.id_user)
    // getListAbsence(id)
  }
  const Log = async (idModul, idUser) => {
    var IdUser = idUser
    var IdTraining = idTraining
    var IdModul = idModul
    const data = await LogResult(IdUser, IdTraining, IdModul)
    setDataLog(data.data.data)
  }
  const viewResult = (id) => {
    setModalResult(!modalResult)
    getListResult(id)
    getModul()
  }
  const handleError = () => {
    message.error('Assesment is not taken yet!')
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      render: text => <a>{text}</a>,
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Trainer',
      dataIndex: 'trainer',
      key: 'trainer',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <Button onClick={() => viewResult(Data.id)} type="primary">View</Button>
        </Space>
      ),
    },
  ];

  const columnsAbsence = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Training Step',
      dataIndex: 'modul',
      key: 'modul',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Score',
      dataIndex: 'nilai',
      key: 'nilai',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Time',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => <a>{moment(text).format('L')}</a>,
    },
  ];
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full  bg-white shadow-md p-3">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'> Daftar Training Class</div>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <Table
                columns={columns}
                dataSource={dataClass}
                scroll={{ x: 1000, y: 500 }}
                pagination={true}
                bordered
              />
            </div>
          </div>
          <Modal title="Class Score" width={1500} visible={modalResult} onOk={e => setModalResult(false)} onCancel={e => setModalResult(false)}>
            <Table
              columns={
                dataModul.map((data, idx) => (
                  data.name === 'name' ?
                    {
                      title: data.name,
                      dataIndex: data.name,
                      key: idx,
                      align: 'center',
                      render: (text) => (
                        <Space size="middle">
                          <a type="primary">{text}</a>
                        </Space>
                      )
                    } :
                    {
                      title: data.name,
                      dataIndex: data.name,
                      key: idx,
                      align: 'center',
                      render: (text) => (
                        <Space size="middle" onClick={() => text.nilai === undefined ? handleError() : handleLog(text)}>
                          <a type="primary">{text.nilai === undefined ? 'Assesment is not taken yet!' : text.nilai >= 70 ? 'Sudah lulus assement(' + text.nilai + ')' : 'Belum lulus assement(' + text.nilai + ')'}</a>
                        </Space>
                      )
                    }
                ))}
              dataSource={dataSchedule}
              pagination={true}
              scroll={{ x: 1000, y: 500 }}
              bordered
            />
          </Modal>
          <Modal width={1000} title="Log" visible={modalLog} onOk={e => setModalLog(false)} onCancel={e => setModalLog(false)}>
            <Table
              columns={columnsAbsence}
              dataSource={dataLog}
              pagination={true}
              bordered
            />
          </Modal>

        </div>
      </div>
    </>
  )
}
