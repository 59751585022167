import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Assets/css/main.css'
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
