import { Empty, message } from "antd";
import React, { useEffect, useState } from "react";
import { ListModulMenu } from "../Configs/Api";

function Index(props) {
  const [Menu, setMenu] = useState([])
  useEffect(() => {
    localStorage.removeItem('TrainingClass')
    GetMenu()
  }, []);
  const handlePresentation = (data) => {
    if (data.status === true) {
      localStorage.setItem('idModul', btoa(data.id))
      localStorage.setItem('TrainingClass', btoa(JSON.stringify(data.id_training_class)))
      props.history.history.push(data.path)
    } else {
      message.error('No schedule for today')
    }
  }
  const GetMenu = async () => {
    const data = await ListModulMenu()
    if (data.status) {
      setMenu(data.data.modul)
    }
  }
  return (
    <div className="container-menu">
      <div className="bg-lm py-2 px-4 mt-1 md:mt-4 text-1xl md:text-2xl">EXPLORE OUR COURSES</div>
      {/* Card is full width. Use in 12 col grid for best view. */}
      {/* Card code block start */}
      <div className="py-6 grid gap-x-8 gap-y-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {Menu && Menu.length > 0 ?
          Menu.map((data, idx) => (
            <div className={`items-center justify-center card-menu text-center rounded p-5 flex items-start shadow sm:px-2 ${data.status ? "bg-white" : "bg-gray-50"}`} key={idx} onClick={e => handlePresentation(data)}>
              <div className="flex flex-col items-center justify-center">
                <img
                  style={{ maxHeight: '100px' }}
                  className="mx-auto"
                  src={data.icon}
                  alt="Workflow"
                />
                <p className="text-gray-500 mt-2 mb-6 text-md  tracking-wide font-bold leading-normal">{data.name} </p>
              </div>
            </div>
          ))
          :
          <Empty />}
      </div>
      {/* Card code block end */}
    </div >
  );
}
export default Index;
