import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useHistory } from "react-router-dom";
import Logo from '../../Assets/brand/android-icon-192x192 copy.png'
import Accelerate from '../../Assets/logo.svg'
import { login } from '../../Utils/Index';
import { LoginUser } from '../../Configs/Api'

function MyApp(props) {
  const [state, setState] = useState({
    email: '',
    password: ''
  });
  const { email, password } = state
  const updateState = (data) => setState((state) => ({ ...state, ...data }));
  const handleLogin = async (e) => {
    var bodyFormData = new FormData();
    bodyFormData.set('email', email);
    bodyFormData.set('password', password);
    const data = await LoginUser(bodyFormData)
    if (data.status) {
      const Login = await login(data);
      if (Login.login && data.data.user.role === 'participant') {
        window.location.reload()
      } else if (Login.login && data.data.user.role === 'trainer') {
        window.location.reload()
      }
    } else {
      error()
    }
  }
  const error = () => {
    message.error('Please check username/password');
  };

  useEffect(() => {
    window.sessionStorage.removeItem('Quiz');
  }, [])

  return (
    <div className="h-full w-full pt-16 pb-20 px-4 bg-login">
      <div className="flex flex-col items-center justify-center">
        <img
          className="w-20"
          src={Logo}
          alt="Workflow"
        />
        <img
          className="logo-accelerate mt-2"
          src={Accelerate}
          alt="Workflow"
        />
        <div className="bg-transparent shadow rounded lg:w-1/3  md:w-1/2 w-full p-5 mt-6">
          <Form
            layout="vertical"
            name="basic"
            onFinish={handleLogin}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input onChange={e => updateState({ email: e.target.value })} size="large" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password onChange={e => updateState({ password: e.target.value })} size="large" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="w-full mt-6">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default MyApp;
