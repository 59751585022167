import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../Utils/Index';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      isLogin().login && restricted && isLogin().role === 'trainer' ?
        // <Redirect to="/dashboard" />
        <Redirect to="/home" />
        : isLogin().login && restricted && isLogin().role === 'participant' ?
          <Redirect to="/home" />
          :
          <Component {...props} />
    )} />
  );
};

export default PublicRoute;