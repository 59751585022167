import React, { useState, useEffect } from 'react';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import Logo from '../Assets//accelerate-logo.png'
import { ChevronDownIcon } from '@heroicons/react/solid'
import {
  Link
} from "react-router-dom";
import { logout } from '../Utils/Index';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HeadersAdmin(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [trainingToogle, setTrainingToogle] = useState(false);
  const [assesmentToogle, setAssesmentToogle] = useState(false);
  const [dataUser, setDataUser] = useState([])
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('User'))
    setDataUser(data)
  }, [])
  const handleLogout = () => {
    logout();
  }
  return (
    <>
      <div className="bg-lm">
        <Disclosure as="nav" className="bg-lm">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="logo-accelerate"
                        src={Logo}
                        alt="Workflow"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="ml-4 flex items-center md:ml-6">
                      <div className='text-gray-200 font-normal text-xs'>{dataUser.name}</div>

                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <Avatar size='large' icon={<UserOutlined />} />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: 100 }}>
                            <Menu.Item key={'password'}>
                              {({ active }) => (
                                <Link to="/change-password"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                  )}
                                >
                                  {'Password'}
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item key={'logout'}>
                              {({ active }) => (
                                <div
                                  onClick={e => handleLogout()}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                  )}
                                >
                                  {'Logout'}
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <div className="flex md:hidden ml-1">
                        <button aria-label="open menu" onClick={() => setShowMenu(true)} className="text-gray-200 dark:text-gray-200 dark:hover:text-gray-300 md:hidden focus:outline-none focus:ring-2 rounded focus:ring-gray-600">
                          <svg className="fill-stroke" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 12H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 18H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </div>
      <ul className="hidden bg-gray-100 md:flex items-center justify-center space-x-8 py-4 mb-0">
        <Link to="/dashboard">
          <li className='text-sm  text-gray-400 border border-white bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  cursor-pointer p-4 font-normal text-xs leading-3 shadow-md'>
            Home
          </li>
        </Link>
        <li>
          <Menu as="div" className="relative inline-block text-gray-600 border border-white bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  cursor-pointer font-normal text-xs leading-3 shadow-md">
            <div>
              <Menu.Button className="inline-flex p-3 justify-center w-full  text-sm  text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-300">
                Training
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items style={{ zIndex: 100 }} className="w-56 origin-top-right absolute left-0 shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/training"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Training
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to={'/training-class'}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}> Training Class </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/materi-training"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Materi Training
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/training-schedule"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Training Schedule
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/training-attedance"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Training Attendance
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/training-absence"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Training Absence
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/training-notes"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Training Notes
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

        </li>
        <li>
          <Menu as="div" className="relative inline-block text-gray-600 border border-white bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  cursor-pointer font-normal text-xs leading-3 shadow-md">
            <div>
              <Menu.Button className="inline-flex p-3 justify-center w-full  text-sm  text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-300">
                Assesment
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items style={{ zIndex: 100 }} className="w-56 origin-top-right absolute left-0 shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/question"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Question
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/result"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Result
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

        </li>
        <Link to="/user">
          <li className='text-sm  text-gray-400 border border-white bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  cursor-pointer p-4 font-normal text-xs leading-3 shadow-md'>User</li>
        </Link>
        <Link to="/feedback">
          <li className='text-sm  text-gray-400 border border-white bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  cursor-pointer p-4 font-normal text-xs leading-3 shadow-md'>Feedback</li>
        </Link>
        <Link to="/location">
          <li className='text-sm  text-gray-400 border border-white bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  cursor-pointer p-4 font-normal text-xs leading-3 shadow-md'>Locations</li>
        </Link>
        <Link to="/home">
          <li className='text-sm  text-gray-400 border border-white bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  cursor-pointer p-4 font-normal text-xs leading-3 shadow-md'>Open Training Web</li>
        </Link>
      </ul>
      <div id="mobile-menu" className={`${showMenu ? "flex" : "hidden"} absolute dark:bg-gray-900 z-10 inset-0 md:hidden bg-white flex-col h-screen w-full`}>
        <div className="border-b bg-lm border-gray-200 dark:border-gray-700 p-2">
          <div className="flex items-center justify-between  border-gray-200 dark:border-gray-700 px-2 py-3">
            <div className="flex items-center space-x-3">
              <img
                className="logo-accelerate"
                src={Logo}
                alt="Workflow"
              />
            </div>
            <button onClick={() => setShowMenu(false)} aria-label="close menu" className="focus:outline-none focus:ring-2 rounded focus:ring-white">
              <svg className="fill-stroke text-gray-200" width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4L4 12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4 4L12 12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
        <div className="p-4 bg-white">
          <ul className="flex flex-col space-y-6">
            <li className="cursor-pointer text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
              <Link to="/dashboard" className="flex items-center">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                  </svg>
                </div>
                <span className="ml-2">Dashboard</span>
              </Link>
            </li>
            <li className="cursor-pointer text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
              <div className="flex items-center" onClick={() => setTrainingToogle(!trainingToogle)}>
                <div className="flex justify-between items-center  w-full">
                  <div className="flex justify-start items-center">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 10L11 14L17 20L21 4L3 11L7 13L9 19L12 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className="ml-2">Training</span>
                  </div>
                  <div className="ml-28 p-1">
                    {trainingToogle ?
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="9 6 15 12 9 18" />
                      </svg>
                    }
                  </div>
                </div>
              </div>
              <ul className={`${trainingToogle ? "" : "hidden"} flex flex-col space-y-1`}>
                <li className="mt-2 cursor-pointer text-gray-200 bg-gray-100 px-2 py-2 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
                  <Link to="/training" className="flex items-center">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                      </svg>
                    </div>
                    <span className="ml-2">Training</span>
                  </Link>
                </li>
                <li className="cursor-pointer text-gray-200 bg-gray-100 px-2 py-2 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
                  <Link to="/training-class" className="flex items-center">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                      </svg>
                    </div>
                    <span className="ml-2">Training Class</span>
                  </Link>
                </li>
                <li className="cursor-pointer text-gray-200 bg-gray-100 px-2 py-2 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
                  <Link to="/materi-training" className="flex items-center">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                      </svg>
                    </div>
                    <span className="ml-2">Materi Training</span>
                  </Link>
                </li>
                <li className="cursor-pointer text-gray-200 bg-gray-100 px-2 py-2 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
                  <Link to="/training-schedule" className="flex items-center">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                      </svg>
                    </div>
                    <span className="ml-2">Training Schedule</span>
                  </Link>
                </li>
                <li className="cursor-pointer text-gray-200 bg-gray-100 px-2 py-2 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
                  <Link to="/training-attendance" className="flex items-center">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                      </svg>
                    </div>
                    <span className="ml-2">Training Attendance</span>
                  </Link>
                </li>
                <li className="cursor-pointer text-gray-200 bg-gray-100 px-2 py-2 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
                  <Link to="/training-absence" className="flex items-center">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                      </svg>
                    </div>
                    <span className="ml-2">Training Absence</span>
                  </Link>
                </li>
                <li className="cursor-pointer text-gray-200 bg-gray-100 px-2 py-2 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
                  <Link to="/training-notes" className="flex items-center">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                      </svg>
                    </div>
                    <span className="ml-2">Training Notes</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="cursor-pointer  text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
              <div className="flex items-center" onClick={() => setAssesmentToogle(!assesmentToogle)}>
                <div className="flex justify-between items-center  w-full">
                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-list-details" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M13 5h8"></path>
                      <path d="M13 9h5"></path>
                      <path d="M13 15h8"></path>
                      <path d="M13 19h5"></path>
                      <rect x="3" y="4" width="6" height="6" rx="1"></rect>
                      <rect x="3" y="14" width="6" height="6" rx="1"></rect>
                    </svg>
                    <span className="ml-2">Assesment</span>
                  </div>
                  <div className="ml-28 p-1">
                    {assesmentToogle ?
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="9 6 15 12 9 18" />
                      </svg>
                    }
                  </div>
                </div>
              </div>
              <ul className={`${assesmentToogle ? "" : "hidden"} flex flex-col space-y-1`}>
                <li className="mt-2 cursor-pointer text-gray-200 bg-gray-100 px-2 py-2 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
                  <Link to="/question" className="flex items-center">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                      </svg>
                    </div>
                    <span className="ml-2">Question</span>
                  </Link>
                </li>
                <li className="cursor-pointer text-gray-200 bg-gray-100 px-2 py-2 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
                  <Link to="/result" className="flex items-center">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                      </svg>
                    </div>
                    <span className="ml-2">Result</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="cursor-pointer text-gray-200 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
              <Link to="/user" className="flex items-center">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                  </svg>
                </div>
                <span className="ml-2">User</span>
              </Link>
            </li>
            <li className="cursor-pointer text-gray-200 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
              <Link to="/feedback" className="flex items-center">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-rss" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="5" cy="19" r="1"></circle>
                    <path d="M4 4a16 16 0 0 1 16 16"></path>
                    <path d="M4 11a9 9 0 0 1 9 9"></path>
                  </svg>
                </div>
                <span className="ml-2">Feedback</span>
              </Link>
            </li>
            <li className="cursor-pointer text-gray-200 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
              <Link to="/location" className="flex items-center">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-2" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="18" y1="6" x2="18" y2="6.01"></line>
                    <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5"></path>
                    <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15"></polyline>
                    <line x1="9" y1="4" x2="9" y2="17"></line>
                    <line x1="15" y1="15" x2="15" y2="20"></line>
                  </svg>
                </div>
                <span className="ml-2">Location</span>
              </Link>
            </li> <li className="cursor-pointer text-gray-200 text-sm leading-3 tracking-normal text-blue-400 focus:text-blue-700 focus:outline-none">
              <Link to='/home' className="flex items-center">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-2" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="18" y1="6" x2="18" y2="6.01"></line>
                    <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5"></path>
                    <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15"></polyline>
                    <line x1="9" y1="4" x2="9" y2="17"></line>
                    <line x1="15" y1="15" x2="15" y2="20"></line>
                  </svg>
                </div>
                <span className="ml-2">Open Training Web</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}