import React, { useEffect, useState, Fragment } from "react";
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import Accelerate from '../../Assets/logo.svg'
import { logout } from '../../Utils/Index';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import DropdownMenu from "./DropdownMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const user = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const Headers = () => {
    const [dataUser, setDataUser] = useState([])
    
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('User'))
        setDataUser(data)
    }, []);

    const handleLogout = () => {
        logout();
    }

    return (
        <>
          <div className="bg-white h-4 w-full fixed z-50">
            <Disclosure as="nav" className="bg-white shadow-md">
              {({ open }) => (
                <>
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                      <div className="flex flex-col md:flex-row md:items-center md:gap-x-16 lg:gap-x-48 h-full">
                        <div className="flex-shrink-0">
                          <Link
                            to="/home"
                            style={{ backgroundImage: `url(${Accelerate})` }}
                            className="h-4 md:h-16 w-24 md:w-48 bg-no-repeat bg-center block mb-2"
                          />
                          {/* <FontAwesomeIcon icon={faBars} className="md:hidden"/> */}
                        </div>
                        <div className='flex gap-x-4 md:gap-x-10'>
                            <Link to="about-us" className="text-black">About</Link>
                            <DropdownMenu />
                        </div>
                      </div>
                      <div>
                        <div className="ml-4 flex items-center md:ml-6">
                          <div className='text-gray-500 text-md'>{dataUser.name}</div>
    
                          {/* Profile dropdown */}
                          <Menu as="div" className="ml-3 relative">
                            <div>
                              <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                                <Avatar size='large' style={{ background: '#17A9E2' }} icon={<UserOutlined />} />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: 100 }}>
                                {dataUser.role === 'trainer' ?
                                  <Menu.Item key={'Open Admin'}>
                                    {({ active }) => (
                                      <Link to="/dashboard"
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                        )}
                                      >
                                        {'Open Admin'}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                  :
                                  null
                                }
                                <Menu.Item key={'progress'}>
                                  {({ active }) => (
                                    <Link to="/my-progress"
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                      )}
                                    >
                                      {'My Progress'}
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item key={'password'}>
                                  {({ active }) => (
                                    <Link to="/change-password"
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                      )}
                                    >
                                      {'Password'}
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item key={'logout'}>
                                  {({ active }) => (
                                    <div
                                      onClick={e => handleLogout()}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                      )}
                                    >
                                      {'Logout'}
                                    </div>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Disclosure>
          </div>
        </>
      )
}

export default Headers