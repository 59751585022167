import React, { useEffect, useState, Fragment, useRef } from 'react';
import { ExclamationIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Table, Space, Form, Input, Button, Modal, Select } from 'antd';
import Header from '../../Components/HeaderAdmin'
import { ListLocation, CreateLocation, DeleteLocation, UpdateLocation } from '../../Configs/Api'

export default function Location() {
  const [Delete, setDelete] = useState(false)
  const [Add, setAdd] = useState(false)
  const [EditLocation, setEditLocation] = useState(false)
  const [idDeleteCLocation, setIdLocationDelete] = useState([])
  const [dataLocation, setDataLocation] = useState([])
  const [dataCreateLocation, setDataCreateLocation] = useState({
    namaCreate: '',
    alamatCreate: '',
  })
  const [dataUpdateLocation, setDataUpdateLocation] = useState({
    id: '',
    namaUpdate: '',
    alamatUpdate: '',
  })
  const cancelButtonRef = useRef(null)
  const { Option } = Select;
  const { id, namaUpdate, alamatUpdate } = dataUpdateLocation
  const updateDataLocation = (data) => setDataUpdateLocation((state) => ({ ...state, ...data }));
  const { namaCreate, alamatCreate } = dataCreateLocation
  const createDataLocation = (data) => setDataCreateLocation((state) => ({ ...state, ...data }));

  useEffect(() => {
    getListLocation()
  }, []);

  const getListLocation = async () => {
    const data = await ListLocation('0')
    if (data.status) {
      const array = []
      data.data.data.map((data, idx) => {
        array.push({
          key: idx + 1,
          'no': idx + 1,
          "nama": data.name,
          "address": data.address,
          "id": data.id,
          "Data": data,
        })
      })
      setDataLocation(array)
    }
  }
  const handleDeleteLocation = async () => {
    var Id = idDeleteCLocation
    const data = await DeleteLocation(Id)
    if (data.status) {
      setIdLocationDelete('')
      getListLocation()
      setDelete(!Delete)
    }
  }
  const deleteLocation = (id) => {
    setIdLocationDelete(id)
    setDelete(!Delete)
  }

  const createLocation = async () => {
    var formData = new FormData();
    formData.append('name', namaCreate);
    formData.append('address', alamatCreate);
    const data = await CreateLocation(formData)
    if (data.status) {
      getListLocation()
      createDataLocation({
        namaCreate: '',
        alamatCreate: '',
      })
      setAdd(!Add)
    }
  }

  const updateLocation = async () => {
    var Id = id
    var formData = new FormData();
    formData.append('name', namaUpdate);
    formData.append('address', alamatUpdate);
    formData.append('_method', 'PUT');
    const data = await UpdateLocation(Id, formData)
    if (data.status) {
      getListLocation()
      updateDataLocation({
        id: '',
        namaCreate: '',
        alamatCreate: '',
      })
      setEditLocation(!EditLocation)
    }
  }
  const handleAddClass = () => {
    getListLocation()
    setAdd(!Add)
  }
  const handleEditLocation = (data) => {
    updateDataLocation({
      id: data.id,
      namaUpdate: data.nama,
      alamatUpdate: data.address,
    })
    getListLocation()
    setEditLocation(!EditLocation)
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Nama',
      dataIndex: 'nama',
      key: 'nama',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Alamat',
      dataIndex: 'address',
      key: 'address',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'Data',
      align: 'center',
      width: '10%',
      render: (Data) => (
        <Space size="middle">
          <PencilIcon onClick={() => handleEditLocation(Data)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
          <TrashIcon onClick={() => deleteLocation(Data.id)} className="h-4 w-4 mx-1 cursor-pointer" aria-hidden="true" />
        </Space>
      ),
    },
  ];
  return (
    <>
      <Header />
      <div className="px-6 py-5 bg-gray-100 min-h-half">
        <div className="flex flex-col w-full  bg-white shadow-md p-3 min-h-half">
          <div className='flex line-bottom-gray pb-2 justify-between'>
            <div className='font-bold text-sm text-gray-500  py-2'> Daftar Location</div>
            <Button type="primary" onClick={() => handleAddClass()}>Tambah</Button>
          </div>
          <div className="w-full h-full mt-4">
            <div>
              <Table
                columns={columns}
                dataSource={dataLocation}
                scroll={{ x: 1000, y: 500 }}
                pagination={true}
                bordered
              />
            </div>
          </div>
          <Transition.Root show={Delete} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setDelete}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Delete Training Class
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete this Class ? All of your data will be permanently removed.
                              This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => handleDeleteLocation()}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setDelete(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
          <Modal title="Ubah Class" visible={EditLocation} onOk={e => updateLocation()} onCancel={e => setEditLocation(false)}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Nama">
                <Input value={namaUpdate} onChange={e => updateDataLocation({ namaUpdate: e.target.value })} />
              </Form.Item>
              <Form.Item label="Alamat">
                <Input.TextArea value={alamatUpdate} onChange={e => updateDataLocation({ alamatUpdate: e.target.value })} />
              </Form.Item>
            </Form>
          </Modal>
          <Modal title="Tambah Location" visible={Add} onOk={e => createLocation()} onCancel={e => setAdd(false)}>
            <Form
              layout="vertical"
            >
              <Form.Item label="Nama">
                <Input value={namaCreate} onChange={e => createDataLocation({ namaCreate: e.target.value })} />
              </Form.Item>
              <Form.Item label="Alamat">
                <Input.TextArea value={alamatCreate} onChange={e => createDataLocation({ alamatCreate: e.target.value })} />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  )
}
