import React, { useEffect, useState } from "react";
import { ListModulMenu } from "../../../Configs/Api";
import HeadersNew from '../../../Components/New/Headers';
import ProgressContent from "../../../Components/New/ProgressContent";



const Progress = () => {
    const [dataProgress, setDataProgress] = useState(null);

    const getData = async () => {
        const data = await ListModulMenu();
        setDataProgress(data.data.modul);
    } 

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="pb-8">
            <HeadersNew />
            {dataProgress && <ProgressContent data={dataProgress} />}
        </div>
    )
}

export default Progress