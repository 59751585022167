import React from "react";
// import BgMenu from '../../Assets/accelerate-jumborton-bg.png';
import BgMenu from '../../Assets/about-jumborton-bg.png';
// import JumbotronLogo from '../../Assets/accelerate-jumborton-logo.svg';

const JumbotronAbout = () => {
    return (
    <div className="h-fit">
        <section className="h-full">
        <div className="w-full h-full relative pb-40 pt-32 md:pt-52">
            <img className="absolute md:w-full inset-0 h-full object-cover object-center" src={BgMenu} alt="we care family" />
            <div className="lg:flex items-center relative z-10">
            <div className="flex flex-col items-center w-full px-4">
                <h1 tabIndex="0" className="text-indigo-50 text-2xl lg:text-5xl font-bold mb-8 md:mb-12">About</h1>
                <p tabIndex="0" className="text-indigo-50 text-xl md:text-2xl mb-4 text-center md:px-16 max-w-screen-lg">
                With more than 200 Loan Advisers and more than 20 offices across Indonesia, Loan Market Indonesia began its operations in 2017, whereas until now, Loan Market Indonesia has established partnerships with more than 35 lenders. In order to continuously grow, Loan Market Indonesia developed Accerelate for our advisers and leaders in obtaining the necessary skills and productivity to help Indonesians achieve their financial goals.
                </p>
            </div>
                {/* <Player
                className="w-full"
                poster="https://www.loanmarket.co.id/api_accelerate/public/img/thumbnail/video-thumbnail.jpg"
                src="https://loanmarket.co.id/api_accelerate/public/video/accelerate.mp4"
                /> */}
            </div>
        </div>
        </section>
    </div>
    )
}

export default JumbotronAbout