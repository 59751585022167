import React, { Component, Suspense } from 'react';
import { BrowserRouter as Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import User from './Layouts/User/Index'
import Admin from './Layouts/Admin/Dashboard'
import TrainingClass from './Layouts/Admin/TrainingClass'
import MateriTraining from './Layouts/Admin/MateriTraining'
import Training from './Layouts/Admin/Training'
import TrainingAttedance from './Layouts/Admin/TrainingAttendance'
import TrainingSchedule from './Layouts/Admin/TrainingSchedule'
import TrainingAbsence from './Layouts/Admin/TrainingAbsense'
import TrainingNotes from './Layouts/Admin/TrainingNotes'
import Question from './Layouts/Admin/Question'
import Result from './Layouts/Admin/Result'
import Users from './Layouts/Admin/User'
import Feedback from './Layouts/Admin/Feedback'
import Location from './Layouts/Admin/Location'

import LmWay1 from './Layouts/User/New/LmWay1';

import Login from './Layouts/User/LoginPage'
import ChangePassword from './Layouts/User/ChangePassword'
import Progress from './Layouts/User/New/Progress';

import PrivateRoute from './Components/PrivateRoute';
import PublicRoute from './Components/PublicRoute';
import AdminRoute from './Components/AdminRoute';
import About from './Layouts/User/New/About';

const Page = React.lazy(() => import('./Layouts/User/New/ModulIntro'));
const Presentation = React.lazy(() => import('./Layouts/User/Presentation'));
const Menu = localStorage.getItem('Menu');


const history = createBrowserHistory();
class App extends Component {
  constructor(props) {
    super(props);
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  );
  render() {
    return (
      <Route history={history}>
        <Suspense fallback={this.loading()}>
          <Switch>
            <PublicRoute restricted={true} component={Login} path="/login" exact />
            <AdminRoute component={Admin} path="/dashboard" exact />
            <AdminRoute component={MateriTraining} path="/materi-training" exact />
            <AdminRoute component={TrainingClass} path="/training-class" exact />
            <AdminRoute component={Training} path="/training" exact />
            <AdminRoute component={TrainingAttedance} path="/training-attedance" exact />
            <AdminRoute component={TrainingSchedule} path="/training-schedule" exact />
            <AdminRoute component={TrainingAbsence} path="/training-absence" exact />
            <AdminRoute component={TrainingNotes} path="/training-notes" exact />
            <AdminRoute component={Question} path="/question" exact />
            <AdminRoute component={Result} path="/result" exact />
            <AdminRoute component={Users} path="/user" exact />
            <AdminRoute component={Feedback} path="/feedback" exact />
            <AdminRoute component={Location} path="/location" exact />
            <PrivateRoute component={ChangePassword} path="/change-password" exact />
            <PrivateRoute component={Progress} path="/my-progress" exact />
            <PrivateRoute component={User} path="/home" exact />
            <PrivateRoute component={LmWay1} path="/lm-way-1" exact />
            <PrivateRoute component={About} path="/about-us" exact />
            {Menu ?
              JSON.parse(Menu).map((route, idx) => {
                return (
                  <PrivateRoute
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    component={(props) => <Page data={route} dataIndex={idx} {...props} />}
                  />
                );
              }) : null}
            {Menu ?
              JSON.parse(Menu).map((route, idx) => {
                return (
                  <PrivateRoute 
                    key={idx} 
                    path={`${route.path}-material`}
                    exact={route.exact}
                    name={route.name} 
                    component={(props) => <Presentation data={route} dataNext={idx+1} {...props} />} 
                  />
                );
              }) : null}
            <Redirect from="/" to='/login' />
          </Switch>
        </Suspense>
      </Route>
    );
  }
}

export default App;
